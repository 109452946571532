<template>
  <b-container>
    <admin-base />
    <b-row class="justify-content-md-center admin-row">
      <b-col lg=6 class="tb-rounded-border" style="padding: 15px">
        <h1 class="tb-title-2">Options pour la page <a href="/">"Le projet"</a></h1>
        <b-button variant=outline-secondary v-on:click="delete_route_data()">Delete all route data</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { api_call } from '@/plugins/utils.js'
import AdminBase from '@/components/admin/AdminBase.vue'

export default {
  name: 'Admin',
  components: {
    AdminBase
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    delete_route_data () {
      api_call()
        .delete("/route/data")
        .then(response => {
          console.log(response.data)
        })
    }
  },
}
</script>

<style scoped>
.admin-row {
  margin-top: 5px;
}
</style>
