<template>
  <b-container fluid>
    <b-row v-if="list_id">
      <b-col lg=3>
        <shopping-list-select :cur_list_id="list_id"/>
      </b-col>
      <b-col lg=9>
        <shopping-list-view :list_id="list_id" v-if="list_id >= 0"/>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ShoppingListView from '@/components/shoppingList/ShoppingListView.vue'
import ShoppingListSelect from '@/components/shoppingList/ShoppingListSelect.vue'

export default {
  components: {
    ShoppingListView,
    ShoppingListSelect,
  },
  name: 'AdminListeCourses',
  data () {
    return {
      list_id: null,
    }
  },
  created() {
    if (this.$route.params.id != undefined) {
      this.list_id = parseInt(this.$route.params.id)
    }
    else {
      this.list_id = -1
    }
  },
  methods: {
  },
}
</script>

<style scoped>
</style>