<template>
  <b-col v-if="horraires">
    <b-form @submit="update_horraires" class="form-base-class form-full-width-items">
      <b-form-group
        id="horraires"
        label="Horraires du food truck:"
        label-for="horraires"
        description=""
      >
        <b-form-textarea
          id="horraires"
          v-model="horraires.value"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </b-form-group>
      <div class="form-validate-buttons">
        <b-button type="submit" variant="success">
          <b-icon icon="pencil"/> Modifier les horraires
        </b-button>
      </div>
      <p class="form-error-msg" v-if="error.is_error">
        {{ error.msg }}
      </p>
    </b-form>
  </b-col>
</template>

<script>
import { api_call, date_to_str, str_to_date } from '@/plugins/utils.js'

export default {
  name: 'DestinationHorraire',
  data () {
    return {
      horraires: null,
      error: {
        is_error: false,
        msg: null,
      },
    }
  },
  created () {
    this.get_horraires()
  },
  methods: {
    update_horraires(event) {
      event.preventDefault()
      api_call()
        .post("/data/admin/destinations_horraires", {
          data: this.horraires,
        })
        .then(response => {
          this.error.is_error = false
          this.get_horraires()
        })
        .catch(error => {
          this.error.is_error = true
          this.error.msg = error.response.data
        })
    },
    get_horraires() {
      api_call()
        .get("/data/public/destinations_horraires")
        .then(response => {
          this.horraires = response.data
        })
    },
  },
}
</script>