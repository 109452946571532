<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <cgu_div />
    </b-row>
  </b-container>
</template>

<script>
import cgu_div from '@/components/utils/cgu_div.vue'

export default {
  name: 'GCU',
  components: {
    cgu_div
  },
}
</script>

<style>
p {
  text-align: justify;
}
</style>