<template>
  <carousel-3d
    v-if="photos"
    :controls-visible="false"
    :autoplay="true"
    :autoplay-timeout="5000"
    :width="sliderWidth"
    :height="sliderHeight"
    loop
  >
    <slide :index="key" :key="key" v-for="(img, key) in photos">
      <img :src="img.pathLong" class="" >
    </slide>
  </carousel-3d>
</template>

<script>
import { is_phone } from "@/plugins/utils.js"
export default {
  name: "PhotosSlider",
  data() {
    return {
      imageDir: "@/../public/assets/photos_presentation",
      photos: null,
    }
  },
  mounted() {
    this.importAll(require.context('@/../public/assets/photos_presentation', true, /\.jpg$/))
  },
  methods: {
    importAll(r) {
      var photos = []
      r.keys().forEach(key => (photos.push({ pathLong: r(key), pathShort: key })));
      this.photos = photos;
    }
  },
  computed: {
    sliderWidth() {
      if (is_phone()) {
        return 250
      }
      return 480
    },
    sliderHeight() {
      return Math.floor(this.sliderWidth / (16/9))
    },
  },
}
</script>
