<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <b-col lg=10 align="center">
        <h1 class="tb-title">Règlement de la cotisation</h1>
        <p>Prix libre, vous donnez ce que vous voulez</p>
        <p class="text-muted"><i>Attention, notre association ne fournit pas de reçu fiscal suite aux dons, ils ne peuvent donc pas être défiscalisés.</i></p>
        <b-button variant="outline-secondary" size="lg" href="https://www.helloasso.com/associations/tim-et-bastien/formulaires/1" target="_blank">Paiement via la plateforme Helloasso ici</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {
  name: 'Faire_un_don',
}
</script>

<style>
</style>
