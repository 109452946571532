<template>
  <fragment>
    <image-and-text-row
      :image="require('@/../public/assets/photos/new_food_truck.jpg')"
      href="/le-food-truck"
      color-reverse
    >
      <h1>Restaurer : Le food truck</h1>
      <p>Depuis septembre 2021, l'association a ouvert un food truck pour se rendre sur les campus étudiants de l'Université de Lorraine, principalement de la Métropole du Grand Nancy.</p>
      <p>L'offre alimentaire proposée se veut végétarienne, de qualité, durable mais aussi accessible.</p>

    </image-and-text-row>
    <image-and-text-row
      :image="require('@/../public/assets/photos/sensibiliser_low.jpg')"
      href="/sensibiliser"
      reverse
    >
      <h1>Sensibiliser</h1>
      <p>Afin d'accompagner les étudiant.e.s dans une démarche d'amélioration de leurs habitudes alimentaires, de nombreuses actions de sensibilisation ont eu lieu et d'autres sont en cours de création.</p>

    </image-and-text-row>
    <image-and-text-row
      :image="require('@/../public/assets/photos/accompagner_low.jpg')"
      href="/accompagner"
      color-reverse
    >
      <h1>Accompagner</h1>
      <p>L'association accompagne les projets alimentaires innovants et étudiants qui émergent en France. L'objectif est de faciliter le transfert de connaissances et d'accélérer le déploiement de projets alimentaires étudiants, durables et responsables.</p>

    </image-and-text-row>
    <image-and-text-row
      :image="require('@/../public/assets/photos/coupage_carotte.jpg')"
      reverse
      no-link
    >
      <stats />
    </image-and-text-row>
  </fragment>
</template>

<script>
import ImageAndTextRow from '../utils/ImageAndTextRow.vue'
import Stats from '@/components/projet/Stats.vue'

export default {
  components: {
    ImageAndTextRow,
    Stats,
  },
  name: "Projets",
}
</script>

<style scoped>

</style>