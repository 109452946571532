<template>
  <b-container>
    <photo-title
      :image="require('@/../public/assets/photos/coupage_legume_2.jpg')"
    >Le food truck</photo-title>
    
    <b-row class="justify-content-md-center">
      <b-col lg=12 align="center">
        <master-title-2>Présentation du food truck</master-title-2>
        <photo-text :image="require('@/../public/assets/restaurer/food_truck.jpg')" width="60%"/>
        <p class="large-text">Depuis septembre 2021, l'association a ouvert un food truck pour se rendre sur les campus étudiants de l'Université de Lorraine, principalement de la Métropole du Grand Nancy.</p>
        <p class="large-text">L'offre alimentaire proposée se veut végétarienne, de qualité et durable (au maximum bio, local, de saison et en circuit court) mais aussi accessible : 1€ pour les boursier.e.s et 3,30€ pour les non boursier.e.s (formule comprenant 1 plat + 1 dessert).</p>
        <p class="large-text">Dans une perspective d'amélioration des approvisionnements, nous avons débuté en mars 2023 un partenariat avec la Grande Epicerie Générale : un supermarché participatif à Nancy permettant de s'approvisionner localement. Ce partenariat nous permet de nous assurer que nos produits sont issus de circuits courts et garantit une juste rémunération des différents acteurs de la filière (producteurs.rices, transformateurs, etc.) ainsi que des pratiques agricoles respectueuses de l'environnement. Cela nous permet également de nous inscrire dans une initiative locale qui porte des réflexions sur l'accès à l'alimentation saine et de qualité ainsi que de lien entre alimentation et citoyenneté cohérentes avec les valeurs de notre association.</p>

        <photo-text :image="require('@/../public/assets/restaurer/fournisseurs.jpg')" width="60%"/>
        <p class="large-text"><b>Nos fournisseurs :</b></p>
        <ul class="large-text">
          <li>La crèmerie Navet (crèmerie) - 54000 Nancy</li>
          <li>La Grande épicerie générale (produits secs) - 54000 Nancy</li>
          <li>Yann Doridant (fruits et légumes) - 54770 Amance</li>
          <li>Emmanuel Thiebot (Légumes, fruits, jus, œufs) - 88330 Damas-aux-Bois</li>
          <li>Regain (pain, farine) - 54520 Laxou</li>
          <li>Moulin des Essarts, ferme des Essarts (jus, confitures, pommes) - 88170 Rémoville</li>
        </ul>

        <legumes-line />
        <p class="large-text">En lien avec l'Université de Lorraine (UL), nous ciblons les campus sur lesquels il y a peu - pas d'offre alimentaire. Notre proposition se veut complémentaire à celle du CROUS de Lorraine. La recherche des emplacements est réalisée en partenariat avec l'UL qui compte 49 sites universitaires.</p>
        <p class="large-text">Notre équipe à la volonté d'accompagner les étudiant.e.s dans leurs transitions alimentaires : celle de quitter le foyer familial et d'apprendre à bien manger.</p>
        <p class="large-text">Venir se restaurer au food truck est avant tout l'occasion de partager un repas de qualité, dans une atmosphère de convivialité et d'écoute. Pour aller plus loin dans l'expérience, les étudiant.e.s ont la possibilité de venir donner un coup de main dans la cuisine du Food Truck pour se familiariser avec nos techniques de cuisine, et partager un temps d'échange privilégié. Toutes les recettes cuisinées dans notre food truck sont disponibles sur place, et publiées sur notre site internet et nos réseaux sociaux pour permettre à chacun.e de reproduire ses recettes préférées chez soi ! Et pour ne pas les oublier, des posts instagram réguliers mettent en avant des légumes méconnus.</p>
        <photo-text :image="require('@/../public/assets/restaurer/cuisine.jpg')" width="60%"/>
        <p class="large-text">Le food truck est aussi un lieu d'échange et de partage : dès 10h et jusqu'en milieu d'après-midi, les étudiant.e.s peuvent y acheter des cookies et des cafés pendant leurs pauses. C'est une bonne occasion pour discuter avec elles.eux et mieux comprendre les difficultés de chacun.e à s'alimenter sainement. De ces échanges sont nées des idées pour améliorer nos actions.</p>
        <p class="large-text">Le food-truck reste en moyenne 1 à 1,5 mois par campus. Nous souhaitons répondre aux besoins du plus grand nombre, tout en s'adaptant aux spécificités et contraintes de chaque étudiant.e. Pour ce faire, nous restons plus longtemps sur les campus avec le food truck pour prendre le temps de nous connecter avec les étudiant.e.s et les associations sur place.</p>
      </b-col>
    </b-row>
    <tb-footer />
  </b-container>
</template>

<script>
import MasterTitle2 from '@/components/utils/MasterTitle2.vue'
import PhotoTitle from '@/components/utils/PhotoTitle.vue'
import PhotoText from '@/components/utils/PhotoText.vue'
import LegumesLine from '@/components/utils/LegumesLine.vue'
import TbFooter from '@/components/utils/TbFooter.vue'

export default {
  name: "Le_food_truck",
  components: {
    MasterTitle2,
    PhotoTitle,
    PhotoText,
    LegumesLine,
    TbFooter
  },
}
</script>

<style scoped>
.le-food-truck-photos {
  max-width: 80%;
}
@media only screen and (max-width: 768px) {  /* phones only */
  .le-food-truck-photos {
    max-width: 100%;
  }
}
</style>