<template>
  <b-container>
    <photo-title
      :image="require('@/../public/assets/photos/nos_partenaires.jpg')"
    >Contacts</photo-title>

    <b-row class="justify-content-md-center">
      <b-col lg="12" align="center">
        <master-title>Nous contacter</master-title>
        <p class="large-text-center">Une question pratique par rapport au food truck : <a href="mailto:foodtruck@timbastien.fr">foodtruck@timbastien.fr</a></p>
        <p class="large-text-center">Contacter le bureau de l'association : <a href="mailto:contact@timbastien.fr">contact@timbastien.fr</a></p>
        <p class="large-text-center">Numéro de téléphone Tim & Bastien : <a style="text-decoration:none" href="tel:+33744404184">07 44 40 41 84</a></p>
        <p class="large-text-center">Numéro de téléphone du food truck : <a style="text-decoration:none" href="tel:+33752060604">07 52 06 06 04</a></p>
        <!-- <p class="large-text-center">S'abonner a la newsletter : <a style="text-decoration:none" href="/login">ici</a></p> -->
        <p class="large-text-center">Numéro de siret Tim & Bastien : 89498246100036</p>
        <legumes-line />
        <!-- <div style="min-height: 1rem;"></div>
        <b-button variant="outline" href="https://www.facebook.com/timetbastien" target="_blank"><b-icon font-scale="2" icon="facebook"></b-icon></b-button>
        <b-button variant="outline" href="https://www.instagram.com/timetbastien" target="_blank"><b-icon font-scale="2" icon="instagram"></b-icon></b-button> -->
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <master-title>Suivre nos savoureuses aventures</master-title>
        <div class="tb-btns">
          <b-button href="/login">
            <font-awesome-icon
              style="color: #FC9B37;"
              size="2x"
              icon="fa-solid fa-envelope"
            />
            Abonnez-vous à notre newsletter
          </b-button>
          <b-button href="https://www.instagram.com/timetbastien">
            <font-awesome-icon
              style="color: #952FBE;"
              size="2x"
              icon="fa-brands fa-instagram"
            />
            Insta
          </b-button>
          <b-button href="https://www.facebook.com/timetbastien">
            <font-awesome-icon
              style="color: #3b5998;"
              size="2x"
              icon="fa-brands fa-facebook"
            />
            Facebook
          </b-button>
        </div>
      </b-col>
    </b-row>
    <tb-footer />
  </b-container>
</template>

<script>
import { is_phone } from '@/plugins/utils.js'
import PhotoTitle from '@/components/utils/PhotoTitle.vue'
import MasterTitle from '@/components/utils/MasterTitle.vue'
import LegumesLine from '@/components/utils/LegumesLine.vue'
import TbFooter from '@/components/utils/TbFooter.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'

library.add(faEnvelope)
library.add(faFacebook)
library.add(faInstagram)

export default {
  name: 'Contact',
  components: {
    PhotoTitle,
    MasterTitle,
    LegumesLine,
    TbFooter,
  },
  data () {
    return {
      is_phone: is_phone,
    }
  },
  created () {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed () {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize () {
      this.$forceUpdate()
    },
  },
}
</script>

<style>
</style>
