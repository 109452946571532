<template>
  <div>
    <div class="row-button">
      <b-button variant=outline-danger v-on:click="delete_checked()">Supprimer les <b-icon icon="check-circle"/></b-button>
      <b-button variant=outline-secondary v-on:click="toogle_hide_checked()"><fragment v-if="$store.state.shopping_list_current.hide_checked">Afficher</fragment><fragment v-else>Cacher</fragment> les <b-icon icon="check-circle"/></b-button>
      <b-button variant=outline-secondary v-on:click="toogle_hide_adding()"><fragment v-if="$store.state.shopping_list_current.hide_adding">Afficher</fragment><fragment v-else>Cacher</fragment> les <b-icon icon="plus-circle"/></b-button>
      <api-call-status :status="error.fetch_status"/>
    </div>
    <div v-if="this.$store.state.shopping_list_current.list_id == list_id">
      <div v-for="(cat, cat_idx) in $store.state.ingr_categories" :key="cat_idx">
        <hr />
        <div class="list-title">{{ cat.name }}</div>
        <div v-for="(elem, elem_idx) in $store.state.shopping_list_current.list[cat.name]" :key="elem_idx">
          <div class="list-elem" v-if="$store.state.shopping_list_current.hide_checked == false || ($store.state.shopping_list_current.hide_checked && elem.checked == false)">
            <div class="check-button" @click="change_checked(elem)">
              <b-icon icon="check-circle" variant="success" font-scale="1.5" v-if="elem.checked"></b-icon>
              <b-icon icon="circle" font-scale="1.5" v-else></b-icon>
            </div>
            <b-input class="name-element" v-model="elem.name" v-on:keyup="update_elem_name(elem)" v-bind:class="{ 'name-element-checked': elem.checked }"></b-input>
          </div>
        </div>
        <div class="list-elem" v-if="new_elems && $store.state.shopping_list_current.hide_adding == false">
          <div class="check-button" @click="add_element(new_elems[cat.name], cat.name)">
            <b-icon icon="plus-circle" font-scale="1.5"></b-icon>
          </div>
          <b-input :id="'add-element-id-' + cat.id" class="name-element" v-model="new_elems[cat.name].name" v-on:keyup.enter="add_element(new_elems[cat.name], cat.name, 'add-element-id-' + cat.id)"></b-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api_call } from '@/plugins/utils.js'
import ApiCallStatus from "@/components/utils/ApiCallStatus.vue"

export default {
  name: 'ShoppingListView',
  components: {
    ApiCallStatus
  },
  data () {
    return {
      cur_add_id: 1,
      interval: null,
      new_elems: null,
      new_updates: {},
      elems_to_add: [],
      updating_counter: 0,
      error: { is_error: false, msg: null, fetch_status: "" },
    }
  },
  props: {
    list_id: {
      required: true,
      type: Number
    },
  },
  mounted() {
    if (this.$store.state.ingr_categories.length == 0) {
      api_call()
        .get("/recipes/ingredients/categories")
        .then(categories_list => {
          this.$store.state.ingr_categories = categories_list.data
          this.new_elems = {}
          for (const [key, obj] of Object.entries(this.$store.state.ingr_categories)) {
            this.new_elems[obj.name] = {
              name: "",
              categorie_id: obj.id,
            }
          }
          this.init()
        })
    }
    else {
      this.new_elems = {}
      for (const [key, obj] of Object.entries(this.$store.state.ingr_categories)) {
        this.new_elems[obj.name] = {
          name: "",
          categorie_id: obj.id,
        }
      }
      this.init()
    }
  },
  destroyed() {
    clearInterval(this.interval)
  },
  methods: {
    init() {
      // first load list if needed
      if (Object.keys(this.$store.state.shopping_list_current).length == 0
      || this.$store.state.shopping_list_current.list_id != this.list_id) {
        api_call()
          .get("/shopping_list/" + this.list_id + "/element")
          .then(response => {
            this.$store.commit('shopping_list_set_id', this.list_id)
            this.$store.commit('shopping_list_set_list', response.data)
          })
          .catch(error => {
            this.$store.commit('shopping_list_set_id', null)
          })
      }
      else {  // if list already charged -> update it
        this.trying_update()
      }
      this.interval = setInterval(this.recurentCallback, 5000);
      window.onbeforeunload = function(event) {
        return confirm("Confirm refresh")
      };
    },
		recurentCallback() {
      // console.log("saving")
      this.save_online()
    },
    trying_update() {
      if (this.updating_counter == 0) {
        api_call()
          .get("/shopping_list/" + this.list_id + "/element")
          .then(response => {
            this.$store.commit('shopping_list_set_id', this.list_id)
            this.$store.commit('shopping_list_set_list', response.data)
            this.error.fetch_status = "ok"
          })
          .catch(error => {
            this.error.fetch_status = "error"
          })
      }
    },
    save_online() {
      if (this.updating_counter > 0) {
        console.log("cannot update recipes, another update is in progress")
        return
      }
      this.error.fetch_status = "loading"
      this.updating_counter += 1
      for (var i = this.elems_to_add.length - 1; i >= 0; i--) {
        this.updating_counter += 1
        this.trying_update()
        api_call()
          .post("/shopping_list/" + this.list_id + "/element", {
            name: this.elems_to_add[i].name,
            categorie: this.elems_to_add[i].categorie_id,
            checked: this.elems_to_add[i].checked,
          })
          .then(response => {
            this.updating_counter -= 1
            this.elems_to_add.splice(i, 1)
            this.trying_update()
          })
          .catch(error => {
            this.updating_counter -= 1
            console.error("unable to add element")
            this.trying_update()
          })
      }
      for (const [key, obj] of Object.entries(this.new_updates)) {
        if (obj.delete.use == true) {
          this.updating_counter += 1
          api_call()
            .delete("/shopping_list/" + this.list_id + "/element/" + key)
            .then(response => {
              this.updating_counter -= 1
              delete this.new_updates[key]
              // console.log("element deleted")
              this.trying_update()
            })
            .catch(error => {
              this.updating_counter -= 1
              console.error("unable to delete element")
              this.trying_update()
            })
        }
        else {
          var elements = {}
          if (obj.update_name.use) {
            elements.name = obj.update_name.new_name
          }
          if (obj.update_checked.use) {
            elements.checked = obj.update_checked.new_checked
          }
          if (Object.keys(elements).length > 0) {
            this.updating_counter += 1
            api_call()
              .put("/shopping_list/" + this.list_id + "/element/" + key, elements)
              .then(response => {
                this.updating_counter -= 1
                delete this.new_updates[key]
                // console.log("element updated")
                this.trying_update()
              })
              .catch(error => {
                this.updating_counter -= 1
                console.error("unable to update element")
                this.trying_update()
              })
          }
          else {
            delete this.new_updates[key]
          }
        }
      }
      this.updating_counter -= 1
      this.trying_update()
    },
    change_elem(elem) {
      if (!(elem.id in this.new_updates)) {
        this.new_updates[elem.id] = {
          delete: {
            use: false,
          },
          update_name: {
            use: false,
            new_name: '',
          },
          update_checked: {
            use: false,
            new_checked: false,
          },
          info: {
            cat_name: elem.categorie.name,
            elem_id: elem.id,
          },
        }
      }
    },
    add_element(elem, cat_name, input_id) {
      if (elem.name == "") {
        return
      }
      this.cur_add_id += 1
      this.elems_to_add.push({
        add_id: this.cur_add_id,
        name: elem.name,
        categorie_id: elem.categorie_id,
        shoopping_list_id: this.$store.state.shopping_list_current.list_id
      })
      this.$store.state.shopping_list_current.list[cat_name].push({
        checked: false,
        name: elem.name,
        categorie: {
          name: cat_name,
        },
        is_new: true,
        add_id: this.cur_add_id,
      })
      elem.name = ""
      var input_element = document.getElementById(input_id)
      input_element.value = ""
      this.save_online()
      // this.$forceUpdate()
    },
    change_checked(elem) {
      elem.checked = !elem.checked
      if ('is_new' in elem && elem.is_new == true) {
        var changed_elem = null
        for (const [key, obj] of Object.entries(this.elems_to_add)) {
          if (obj.add_id == elem.add_id) {
            changed_elem = obj
            break
          }
        }
        if (changed_elem) {
          changed_elem.checked = elem.checked
        }
      }
      else {
        this.change_elem(elem)
        this.new_updates[elem.id].update_checked.use = true
        this.new_updates[elem.id].update_checked.new_checked = elem.checked
      }
    },
    update_elem_name(elem) {
      var is_new = false
      var new_elem = null
      var new_elem_idx = null
      if ('is_new' in elem && elem.is_new == true) {
        is_new = true
        for (const [key, obj] of Object.entries(this.elems_to_add)) {
          if (obj.add_id == elem.add_id) {
            new_elem_idx = key
            new_elem = obj
            break
          }
        }
      }
      else {
        this.change_elem(elem)
      }

      if (elem.name == "") {
        if (is_new) {
          this.elems_to_add.splice(new_elem_idx, 1)
          for (var i = 0; i < this.$store.state.shopping_list_current.list[elem.categorie.name].length; i++) {
            if (this.$store.state.shopping_list_current.list[elem.categorie.name][i].add_id == new_elem.add_id) {
              this.$store.state.shopping_list_current.list[elem.categorie.name][i]
              this.$store.state.shopping_list_current.list[elem.categorie.name].splice(i, 1)
              break
            }
          }
        }
        else {
          this.new_updates[elem.id].delete.use = true
          for (var j = 0; j < this.$store.state.shopping_list_current.list[elem.categorie.name].length; j++) {
            if (this.$store.state.shopping_list_current.list[elem.categorie.name][j].id == elem.id) {
              this.$store.state.shopping_list_current.list[elem.categorie.name][j]
              this.$store.state.shopping_list_current.list[elem.categorie.name].splice(j, 1)
              break
            }
          }
        }
      }
      else {
        if (is_new) {
          new_elem.name = elem.name
        }
        else {
          this.new_updates[elem.id].update_name.use = true
          this.new_updates[elem.id].update_name.new_name = elem.name
        }
      }
    },
    delete_checked() {
      if (!window.confirm("Voulez-vous vraiment supprimer les éléments checkés ?"))
        return
      for (const [cat_name, cat_content] of Object.entries(this.$store.state.shopping_list_current.list)) {
        for (const [key, elem] of Object.entries(cat_content)) {
          if (elem.checked) {
            this.change_elem(elem)
            this.new_updates[elem.id].delete.use = true
            for (var i = 0; i < this.$store.state.shopping_list_current.list[elem.categorie.name].length; i++) {
              if (this.$store.state.shopping_list_current.list[elem.categorie.name][i].id == elem.id) {
                this.$store.state.shopping_list_current.list[elem.categorie.name][i]
                this.$store.state.shopping_list_current.list[elem.categorie.name].splice(i, 1)
                break
              }
            }
          }
        }
      }
    },
    toogle_hide_checked() {
    this.$store.commit('shopping_list_hide_checked', !this.$store.state.shopping_list_current.hide_checked)
      this.$forceUpdate()
    },
    toogle_hide_adding() {
      this.$store.commit('shopping_list_hide_adding', !this.$store.state.shopping_list_current.hide_adding)
      this.$forceUpdate()
    },
  },
}
</script>

<style scoped>
.row-button {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-top: 5px;
  margin-left: 5px;
}
.row-button>* {
  margin-right: 5px;
  margin-bottom: 5px;
}

.list-title {
  font-weight: bold;
}
.list-elem {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.check-button {
  padding: 5px;
}
.name-element {
  border: none;
}
.name-element-checked {
  color: var(--disabled-color);
}
</style>