<template>
  <b-container>
    <b-row>
        <b-col>
          <h1 class="tb-title">Les destinations du food truck</h1>
          
          <fragment v-if="destinations">
            <!-- <div v-for="(dest, key) in destinations" :key="key"> -->
            <div v-for="(dest, key) in destinations.slice().reverse()" :key="key">
              <hr class="destinations-separator" v-if="key > 0"/>
              <p class="date">{{ dest.campus_name }}</p>
              <p class="address">Du {{ dest.start_date }} au {{ dest.end_date }}<br>{{ dest.campus_address_street }}, {{ dest.campus_address_postcode }} {{ dest.campus_address_city }}</p>
            </div>
          </fragment>
        </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { api_call, str_to_date, date_to_long_str } from '@/plugins/utils.js'

export default {
  name: 'Ou_sommes_nous',
  components: {
  },
  data () {
    return {
      horraires: null,
      destinations: null,
    }
  },
  mounted () {
    api_call()
      .get("/data/public/destinations_horraires")
      .then(response => {
        this.horraires = response.data.value.replaceAll("\n", "<br>")
      })
    api_call()
      .get("/destinations?published_only=true")
      .then(response => {
        this.destinations = response.data
        for (var dest of this.destinations) {
          dest.start_date = date_to_long_str(str_to_date(dest.start_date))
          dest.end_date = date_to_long_str(str_to_date(dest.end_date))
        }
      })
  },
}
</script>

<style scoped>
.date {
  text-align: center;
  font-weight: bold;
}
.address {
  font-size: 17px;
  text-align: center;
}
.destinations-separator {
  max-width: 50%;
  margin: auto;
}
@media only screen and (max-width: 768px) {  /* phones only */
  .destinations-separator {
    max-width: 100%;
  }
}
</style>
