<template>
  <b-container>
    <b-row>
      <b-col>
        <h1 class="tb-title align-center">Éditer les menus</h1>
        <menus-edit />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import MenusEdit from '@/components/menu/MenusEdit.vue'
export default {
  name: 'AdminMenus',
  components: {
    MenusEdit
  }
}
</script>

<style scoped>

</style>