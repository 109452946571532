<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <b-col lg=10 align="center">
        <h1 class="tb-title">Notre histoire</h1>
        <p>L'association Tim & Bastien a été créée en février 2021 suite aux lourdes conséquences de la pandémie sur les étudiant.e.s.</p>
        <p>Tim Nicolas et Bastien Maliar, deux étudiants en informatique, ont donc voulu apporter une réponse durable à l'explosion du nombre d'étudiant.e.s en situation de précarité alimentaire.</p>
        <p>Depuis, l'association s'agrandit de jour en jour et intègre de nouveaux projets pour rompre avec la précarité alimentaire structurelle dans le milieu étudiant.</p>
        <p>Nos missions permettent d'accompagner les étudiant.e.s en situation de précarité alimentaire en apportant une solution innovante qui soit en accord avec les enjeux de la transition alimentaire. </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'Notre_histoire',
  data () {
    return {
    }
  },
  mounted () {
  },
}
</script>

<style>
</style>
