<template>  <b-col>
    <b-form @submit="edit_media" class="form-base-class">
      <!-- forms values -->
      <b-form-group label="">
        <img class="media-image-edit-page" :src="this.image_url" />
      </b-form-group>
      <b-form-group label="Nom de l'article:">
        <b-form-input v-model="new_media.name" required></b-form-input>
      </b-form-group>
      <b-form-group label="Date:">
        <b-form-datepicker
          v-model="new_media.date"
          :value-as-date="true"
          start-weekday=1
          locale="fr-FR"
          calendar-width="100%"
          menu-class="w-100"
          required
        />
      </b-form-group>
      <b-form-group label="Lien:">
        <b-form-input v-model="new_media.link"></b-form-input>
      </b-form-group>
      <b-form-group label="Article important ?">
        <b-button :pressed.sync="new_media.is_important" variant="secondary">
          <fragment v-if="new_media.is_important">{{ "Important" }}</fragment>
          <fragment v-else>{{ "Secondaire" }}</fragment>
        </b-button>
      </b-form-group>
      <b-form-group label="Image :" style="max-width: 50%; width: 50%;">
        <b-form-file v-model="media_image" accept="image/jpeg, image/png" plain></b-form-file>
      </b-form-group>
      <!-- validation buttons -->
      <div class="form-validate-buttons">
        <b-button type="submit" variant="success">
          <b-icon icon="pencil"/> Modifier l'article
        </b-button>
        <b-button type="button" v-on:click="delete_media()" variant="danger">
          <b-icon icon="x-circle"/> Supprimer
        </b-button>
      </div>
      <!-- errors -->
      <p class="form-error-msg" v-if="error.is_error">
        {{ error.msg }}
      </p>
    </b-form>
  </b-col>
</template>

<script>
import { api_call, date_to_str, str_to_date, get_image_url } from '@/plugins/utils.js'

export default {
  name: 'DestinationEdit',
  data () {
    return {
      id: null,
      new_media: {
        name: "",
        date: new Date(),
        link: "",
        is_important: false,
      },
      media_image: null,
      image_url: null,
      error: {
        is_error: false,
        msg: null,
      },
    }
  },
  created () {
    this.id = this.media_data.id
    this.reset_dest()
  },
  props: {
    media_data: {
      type: Object,
      required: true,
    },
    updater: {
      type: Object,
      required: true,
    },
  },
  methods: {
    reset_dest () {
      this.new_media.name = this.media_data.name
      this.new_media.date = str_to_date(this.media_data.date)
      this.new_media.link = this.media_data.link
      this.new_media.is_important = this.media_data.is_important
      this.image_url = get_image_url("/media", this.id)
    },
    delete_media() {
      if (!window.confirm("Voulez-vous vraiment supprimer l'article ?"))
        return
      api_call()
        .delete("/media/" + this.id)
        .then(response => {
          console.log("Media deleted")
          this.error.is_error = false
          this.updater.update = !this.updater.update
        })
        .catch(error => {
          this.error.is_error = true
          this.error.msg = error.response.data
        })
    },
    edit_media (event) {
      event.preventDefault()
      api_call()
        .put("/media/" + this.id, {
          name: this.new_media.name,
          date: date_to_str(this.new_media.date),
          link: this.new_media.link,
          is_important: this.new_media.is_important,
        })
        .then(response => {
          console.log("Media modified")
          if (this.media_image) {
            let form_image = new FormData();
            form_image.append('file', this.media_image);
            api_call({ 'Content-Type': 'multipart/form-data' })
              .post("/media/" + this.id + "/image", form_image)
              .then(response => {
                this.error.is_error = false
                this.error.fetch_status = "ok"
                this.updater.update = !this.updater.update
              })
              .catch(error => {
                this.error.is_error = true
                this.error.fetch_status = "error"
                try {
                  this.error.msg = error.response.data
                } catch (error) {
                  this.error.msg = "Unexpected error"
                }
              })
          }
          else {
            this.error.is_error = false
            this.error.fetch_status = "ok"
            this.updater.update = !this.updater.update
          }
        })
        .catch(error => {
          console.log("Error while media modifying")
          this.error.is_error = true
          this.error.msg = error.response.data
        })
    },
  },
}
</script>

<style scoped>
.media-image-edit-page {
  max-height: 5rem;
}
</style>