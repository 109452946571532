import Store from '@/store/index.js'
import axios from 'axios'

const to_euro = (value) => {
	return value.toFixed(2).replace('.', Store.state.float_separator) + '€'
}

var unidecode = require('unidecode');
const get_simple_string = (basestr) => {
	return unidecode(basestr.toLowerCase())
}

const api_call = (headers=null) => {
	var axios_headers = { Authorization: `Bearer ${Store.state.bearer_token}` }
	for (var i in headers) {
    var datum = headers[i];
    axios_headers[datum.key] = datum.val
	}
  var axios_object = axios.create({
		baseURL: Store.state.api_url,
		headers: axios_headers,
  })
	return axios_object
}

const get_image_url = (base_url, id, max_width=0) => {
	return Store.state.api_url + base_url + '/' + id + '/image?max_width=' + max_width
}

const go_back = () => {
	// <b-button v-on:click="go_back()"><b-icon icon="caret-left" /></b-button>
	// data() {
	// 	return {
	// 		go_back: go_back,
	// 	}
	// }
	window.history.back()
}

const is_phone = () => {
	// <fragment v-if="is_phone()">
	//   ...
	// </fragment>
	// <fragment v-else>
	//   ...
	// </fragment>
	// data() {
	// 	return {
	// 		is_phone: is_phone,
	// 	}
	// }
	if (window.innerWidth <= 768)
		return true;
	else
		return false;
}

const str_to_date = (date, sep="/") => {
	var split = date.split(sep)
	return new Date(split[2], split[1] - 1, split[0])
}

const date_to_str = (date, sep="/") => {
	return date.toLocaleDateString('fr-FR').replaceAll("/", sep)
}

const date_to_long_str = (date, with_year=true) => {
	var date_str = date.getDate() + " " + date.toLocaleString('fr', { month: 'long' })
	if (with_year) {
		date_str += " " + date.getFullYear()
	}
	return date_str
}

const capitalize = (s) => {
    return s[0].toUpperCase() + s.slice(1);
}

const sock_emit = (channel, data) => {
	if (Store.state.socket.is_connected) {
		// Store.state.socket.socket.io.opts.extraHeaders.Authorization = `Bearer ${Store.state.bearer_token}`
		Store.state.socket.socket.emit(channel, data)
		return true
	}
	return false
}

const utilsPlugin = {
	install: (Vue) => {
		Vue.prototype.$to_euro = to_euro
		Vue.prototype.$get_simple_string = get_simple_string
		Vue.prototype.$api_call = api_call
		Vue.prototype.$get_image_url = get_image_url
		Vue.prototype.$go_back = go_back
		Vue.prototype.$is_phone = is_phone
		Vue.prototype.$str_to_date = str_to_date
		Vue.prototype.$date_to_str = date_to_str
		Vue.prototype.$date_to_long_str = date_to_long_str
		Vue.prototype.$capitalize = capitalize
		Vue.prototype.$sock_emit = sock_emit
	}
}

export { to_euro, get_simple_string, api_call, get_image_url, go_back, is_phone, str_to_date, date_to_str, date_to_long_str, capitalize, sock_emit }
export default utilsPlugin
