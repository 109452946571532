<template>
  <b-row>
    <b-col id="sumup-connect-col">
      <div>
        <b-button variant=secondary v-on:click="sumup_connect()" :disabled="is_sumup_connected">Connexion</b-button>
        <!-- <b-icon v-if="is_sumup_connected" icon="check2" class="h1" style="margin-bottom: 0px !important;"></b-icon> -->
        <api-call-status status="ok" v-if="is_sumup_connected" />
      </div>
      <div v-if="is_sumup_connected">
        <b-button variant=secondary v-on:click="sumup_fetch_new()" :pressed.sync="auto_fetching">
          <b-icon icon="arrow-repeat"></b-icon>
        </b-button>
        <b-button variant=secondary v-on:click="sumup_fetch_new()">Récuperer les nouvelles transactions</b-button>
        <api-call-status :status="fetching_data.fetch_status" />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { api_call } from '@/plugins/utils.js'
import ApiCallStatus from "@/components/utils/ApiCallStatus.vue"

export default {
  name: 'CompteConnect',
  components: {
    ApiCallStatus,
  },
  data () {
    return {
      interval: null,
      is_sumup_connected: false,
			fetching_data: {
				fetch_status: "No fetching"
			},
			auto_fetching: false,
    }
  },
  mounted () {
		this.interval = setInterval(this.recurentCallback, this.$store.state.update_sumup_interval);
		this.recurentCallback();
  },
  destroyed() {
    clearInterval(this.interval)
  },
  methods: {
		recurentCallback: function () {
			this.updateIsConnected()
      if (this.auto_fetching) {
        this.sumup_fetch_new()
      }
		},
		updateIsConnected: function() {
			if (this.$store.state.is_admin) {
				api_call()
					.get('/sumup/is_connected')
					.then(response => {
            if (response.data.is_connected == false) {
              this.is_sumup_connected = false
            }
            else {
              this.is_sumup_connected = true
            }
					})
					.catch(error => {
						if (error.response.status == 401) {
              this.is_sumup_connected = false
						}
					})
			}
		},
    sumup_connect () {
      var windowReference = window.open();
      api_call()
        .get("/sumup/get_connect_url")
        .then(response => {
          windowReference.location = response.data.url;
        })
    },
    sumup_fetch_new: function() {
			if (this.fetching_data.fetch_status != "loading") {
				console.log("fetching data...")
				this.fetching_data.fetch_status = "loading"
				api_call()
          .get('/sumup/fetch_all')
					.then(() => {
						this.fetching_data.fetch_status = "ok"
					})
					.catch(error => {
						if (error.response.status == 401) {
							this.fetching_data.fetch_status = "error"
						}
						else {
							this.fetching_data.fetch_status = "error"
						}
						if (error.response) {
							console.log("error in /sumup/fetch_all\n"
								+ error.response.status + '\n'
								+ error.response.data)
						}
					})
			}
    },
  },
}
</script>

<style scoped>
#sumup-connect-col {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5px;
  margin-bottom: 15px;
  flex-wrap: wrap;
}
#sumup-connect-col>div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-right: 15px;
}
#sumup-connect-col>div>* {
  margin-right: 5px;
  margin-top: 5px;
}
</style>
