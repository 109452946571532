<template>
  <b-container>
    <b-row>
    </b-row>
    <b-row>
      <b-col lg=8 style="padding: 0px;">
        <recipes-edit-table />
      </b-col>
      <b-col lg=4 style="padding: 0px;">
        <recipes-ingredients />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { api_call } from '@/plugins/utils.js'
import RecipesIngredients from '@/components/recipes/RecipesIngredients.vue'
import RecipesEditTable from '@/components/recipes/RecipesEditTable.vue'

export default {
  name: 'AdminRecettes',
  components: {
    RecipesIngredients,
    RecipesEditTable,
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  },
}
</script>

<style>
</style>
