<template>
  <fragment>
    <td class="input-cell">
      <b-datepicker
        v-model="menu.date"
        :value-as-date="true"
        start-weekday=1
        locale="fr-FR"
        calendar-width="100%"
        menu-class="w-100"
      />
    </td>
    <!-- PLat -->
    <td class="input-cell">
      <div>
        <b-input autocomplete="off" list="recipes-list" class="text-input-no-border" v-model="menu.recipe.name"></b-input>

        <datalist id="recipes-list">
          <option v-for="(recipe, key) in recipes_list" :key="key">{{ recipe.name }}</option>
        </datalist>
      </div>
    </td>
    <td class="input-cell"><b-input type="number" v-model="menu.nb_people"></b-input></td>
    <!-- Dessert -->
    <td class="input-cell">
      <div>
        <b-input autocomplete="off" list="dessert-list" class="text-input-no-border" v-model="menu.dessert.name"></b-input>

        <datalist id="dessert-list">
          <option v-for="(recipe, key) in recipes_list" :key="key">{{ recipe.name }}</option>
        </datalist>
      </div>
    </td>
    <td class="input-cell"><b-input type="number" v-model="menu.dessert_nb_people"></b-input></td>
  </fragment>
</template>

<script>
export default {
  name: 'MenusEditLine',
  props: {
    menu: {
      type: Object,
      required: true,
    },
    recipes_list: {
      type: Array,
      required: true,
    },
  },
}
</script>
