<template>
  <div>
    <div class="accordion" role="tablist" style="margin-bottom: 10px" v-if="recipes">
      <table>
        <tr>
          <th colspan="4">Les recettes</th>
        </tr>
        <fragment v-for="(recipe, idx) in recipes" :key="idx">
          <tr>
            <td class="cell-button recipe-image-in-cell">
              <div v-bind:style="{ backgroundImage: 'url(' + recipe.image_url + ')' }"></div>
            </td>
            <td><fragment v-if="recipe.is_draft">Brouillon</fragment><fragment v-else>Publié</fragment></td>
            <td>{{ recipe.name }}</td>
            <td class="cell-button" style="width:100px;">
              <b-button :href="'/admin/recettes/' + recipe.id" style="width:100%;"><b-icon icon="pencil"/></b-button>
            </td>
          </tr>
        </fragment>
        <div v-if="recipes == null">
          <tr class="form-error-msg" v-if="error.is_error"><td>{{ error.msg }}</td></tr>
        </div>
        <tr>
          <td colspan="4" class="cell-button" style="width:100px;">
            <b-button v-b-toggle="new_recipe.id_collapse" variant="success">
              <b-icon icon="plus"/> Ajouter une Recette
            </b-button>
          </td>
        </tr>
        <tr v-if="new_recipe">
          <td colspan="4" class="form-div-table">
            <b-collapse :id="new_recipe.id_collapse" accordion="recipes-accordion">
              <b-form @submit="add_recipe(-1)" class="form-base-class">
                <!-- forms values -->
                <recipes-edit-form v-model="new_recipe" />
                <!-- validation buttons -->
                <div class="form-validate-buttons">
                  <b-button type="submit" variant="success">
                    <b-icon icon="plus"/> Ajouter
                  </b-button>
                </div>
                <!-- errors -->
                <p class="form-error-msg" v-if="error.is_error">{{ error.msg }}</p>
              </b-form>
            </b-collapse>
          </td>
        </tr>
      </table>
    </div>
    <loading-page :full_page="false" v-else/>
  </div>
</template>

<script>
import { api_call, get_image_url } from '@/plugins/utils.js'
import RecipesEditForm from '@/components/recipes/RecipesEditForm.vue'
import LoadingPage from '@/components/utils/LoadingPage.vue'

export default {
  name: 'RecipesEditTable',
  components: {
    RecipesEditForm,
    LoadingPage,
  },
  data () {
    return {
      recipes: null,
      error: {
        is_error: false,
        msg: null,
      },
      new_recipe: null,
    }
  },
  created() {
    this.update()
  },
  methods: {
    update() {
      this.new_recipe = {
        name: '',
        is_draft: true,
        id_collapse: "add-recipe-in-list"
      }
      api_call()
        .get('/recipes')
        .then(response => {
          this.recipes = response.data
          this.error.is_error = false
          for (var i in this.recipes) {
            this.recipes[i].image_url = get_image_url("/recipes", this.recipes[i].id, 100)
          }
        })
        .catch(error => {
          this.error.is_error = true
          this.error.msg = error.response.data
        })
    },
    get_recipe(id) {
      if (id == -1) {
        return this.new_recipe
      }
      for (var i = 0; i < this.recipes.length; i++) {
        if (this.recipes[i].id == id) {
          return this.recipes[i]
        }
      }
      return null
    },
    add_recipe(id) {
      event.preventDefault()
      var recipe = this.get_recipe(id)
      api_call()
        .post('/recipes', {
          name: recipe.name,
          is_draft: recipe.is_draft,
        })
        .then(response => {
          console.log("Recipe created")
          this.error.is_error = false
          this.update()
        })
        .catch(error => {
          this.error.is_error = true
          this.error.msg = error.response.data
        })
    },
  },
}
</script>

<style scoped>
.form-div-table {
  padding: 0px;
}
.form-div-table>div>form {
  border-style: none;
}

.recipe-edit-ingredient {
  width: 100%;
  max-width: 100%;
}

.recipe-image-in-cell {
  max-width: 5vh;
  width: 5vh;
}
.recipe-image-in-cell>div {
  background-position: 50% 50%;
  background-size: cover;
  width: 4.2vh;
  height: 4.2vh;
  margin: auto;
}
</style>
