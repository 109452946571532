<template>
  <div class="legumes-line">
    <legume v2 legume="abricot" height="2rem" orientation="0deg" />
    <legume v2 legume="aubergine" height="2rem" orientation="0deg" />
    <legume v2 legume="brocoli" height="2rem" orientation="0deg" />
    <legume v2 legume="carotte" height="2rem" orientation="0deg" />
    <legume v2 legume="citrouille" height="2rem" orientation="0deg" />
    <legume v2 legume="cornichon" height="2rem" orientation="0deg" />
    <legume v2 legume="noix" height="2rem" orientation="0deg" />
    <legume v2 legume="oignon" height="2rem" orientation="0deg" />
    <legume v2 legume="poivron" height="2rem" orientation="0deg" />
    <legume v2 legume="pomme" height="2rem" orientation="0deg" />
    <legume v2 legume="tomate" height="2rem" orientation="0deg" />
  </div>
</template>

<script>
import Legume from '@/components/utils/Legume.vue'

export default {
  components: { Legume },
  name: "LegumesLine",
}
</script>

<style scoped>
</style>