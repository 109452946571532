<template>
  <div>
    <recipes-view :recipe_id="parseInt($route.params.id)" />
  </div>
</template>

<script>
import RecipesView from '@/components/recipes/RecipesView.vue'

export default {
  name: 'Nos_recettes_view',
  components: {
    RecipesView,
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  },
}
</script>
