<template>
  <div>
    <div id="recipe-edit-page" v-if="recipe">
      <div id="recipe-edit-page-return-row">
        <div class="return-button">
          <b-button variant=outline-secondary v-on:click="go_back()"><b-icon icon="caret-left" /></b-button>
          <!-- <b-button variant=outline-secondary href="/admin/recettes/"><b-icon icon="caret-left" /></b-button> -->
          <b-button variant=outline-secondary :href="'/recettes/' + recipe.id"><b-icon icon="eye" /></b-button>
        </div>
        <h1 class="tb-title">{{ recipe.name }}</h1>
      </div>
      <div class="recipe-image-edit-page" v-bind:style="{ backgroundImage: 'url(' + this.image_url + ')' }"></div>
      <b-form @submit="edit_recipe()" class="form-base-class">
        <h2 class="tb-title-3" style="max-width: 100%; width: 100%;">Informations générales</h2>
        <!-- forms values -->
        <recipes-edit-form v-model="recipe" />
        <b-form-group label="Source :" style="max-width: 100%; width: 100%;">
          <b-form-input v-model="recipe.source" />
        </b-form-group>
        <b-form-group label="Nb personnes :">
          <b-form-input type="number" v-model="recipe.nb_people_default" required />
        </b-form-group>
        <b-form-group label="Image :" style="max-width: 50%; width: 50%;">
          <b-form-file v-model="recipe_image" accept="image/jpeg, image/png" plain></b-form-file>
        </b-form-group>
        <b-form-group label="Préparation (min) :">
          <b-form-input type="number" v-model="recipe.time_preparation" required />
        </b-form-group>
        <b-form-group label="Repos (min) :">
          <b-form-input type="number" v-model="recipe.time_resting" required />
        </b-form-group>
        <b-form-group label="Cuisson (min) :">
          <b-form-input type="number" v-model="recipe.time_cooking" required />
        </b-form-group>
        <!-- validation buttons -->
        <div class="form-validate-buttons">
          <b-button type="submit" variant="success">
            <b-icon icon="pencil"/> Valider
          </b-button>
          <b-button type="button" v-on:click="delete_recipe()" variant="danger">
            <b-icon icon="x-circle"/> Supprimer la recette
          </b-button>
          <api-call-status :status="error_form_name.fetch_status" />
        </div>
        <!-- errors -->
        <p class="form-error-msg" v-if="error_form_name.is_error">{{ error_form_name.msg }}</p>
      </b-form>
      <h2 class="tb-title-2">Étapes</h2>
      <div v-for="(step, idx) in recipe.steps" :key="idx">
        <recipes-edit-step :recipe_id="recipe.id" :step_id="step.id" v-model="need_update" />
      </div>
      <b-row>
        <b-col lg=6 style="margin-bottom: 10px">
          <b-form @submit="add_step()" class="form-base-class form-full-width-items">
            <h3 class="tb-title-3">Ajouter une étape</h3>
            <!-- forms values -->
            <b-form-group label="Nom de l'étape :">
              <b-form-input v-model="new_step.name" required></b-form-input>
            </b-form-group>
            <!-- validation buttons -->
            <div class="form-validate-buttons">
              <b-button type="submit" variant="success">
                <b-icon icon="plus"/> Ajouter une étape
              </b-button>
              <api-call-status :status="error_new_step.fetch_status" />
            </div>
            <!-- errors -->
            <p class="form-error-msg" v-if="error_new_step.is_error">{{ error_new_step.msg }}</p>
          </b-form>
        </b-col>
        <b-col lg=6 style="margin-bottom: 10px">
          <recipes-steps-order :recipe_id="recipe.id" :steps="recipe.steps" v-model="need_update" v-if="recipe.steps.length >= 2"/>
        </b-col>
      </b-row>
      <p class="form-error-msg" v-if="error.is_error">{{ error.msg }}</p>
    </div>
    <div v-else>
      <b-button href="/admin/recettes"><b-icon icon="caret-left" /> Retour</b-button>
      <p class="form-error-msg" v-if="error.is_error">{{ error.msg }}</p>
      <loading-page :full_page="false" v-else/>
    </div>
  </div>
</template>

<script>
import { api_call, get_image_url, go_back } from '@/plugins/utils.js'
import RecipesEditForm from '@/components/recipes/RecipesEditForm.vue'
import RecipesEditStep from '@/components/recipes/RecipesEditStep.vue'
import ApiCallStatus from "@/components/utils/ApiCallStatus.vue"
import RecipesStepsOrder from '@/components/recipes/RecipesStepsOrder.vue'
import LoadingPage from '@/components/utils/LoadingPage.vue'

export default {
  name: 'RecipeEdit',
  components: {
    RecipesEditForm,
    RecipesEditStep,
    ApiCallStatus,
    RecipesStepsOrder,
    LoadingPage,
  },
  props: {
    recipe_id: {
      type: Number,
      required: true,
    }
  },
  data () {
    return {
      go_back: go_back,
      need_update: { need_update: false },
      recipe: null,
      new_step: {
        name: "",
      },
      recipe_image: null,
      image_url: null,
      error_form_name: { is_error: false, msg: null, fetch_status: "" },
      error_new_step: { is_error: false, msg: null, fetch_status: "" },
      error: { is_error: false, msg: null },
    }
  },
  mounted() {
    this.update()
  },
  methods: {
    update(force_update=false) {
      api_call()
        .get("/recipes/" + this.recipe_id)
        .then(response => {
          this.recipe = response.data
          this.error.is_error = false
          this.image_url = get_image_url("/recipes", this.recipe_id)
          if (force_update) {
            this.$forceUpdate()
          }
        })
        .catch(error => {
          this.error.is_error = true
          this.error.msg = error.response.data
        })
    },
    edit_recipe() {
      event.preventDefault()
      this.error_form_name.fetch_status = "loading"
      api_call()
        .put('/recipes/' + this.recipe.id, {
          name: this.recipe.name,
          is_draft: this.recipe.is_draft,
          nb_people_default: this.recipe.nb_people_default,
          source: this.recipe.source,
          time_preparation: this.recipe.time_preparation,
          time_resting: this.recipe.time_resting,
          time_cooking: this.recipe.time_cooking,
        })
        .then(response => {
          console.log("Recipe updated")
          this.update()
          if (this.recipe_image) {
            let form_image = new FormData();
            form_image.append('file', this.recipe_image);
            api_call({ 'Content-Type': 'multipart/form-data' })
              .post("/recipes/" + this.recipe_id + "/image", form_image)
              .then(response => {
                this.error_form_name.is_error = false
                this.error_form_name.fetch_status = "ok"
                this.update(true)
              })
              .catch(error => {
                this.error_form_name.is_error = true
                this.error_form_name.fetch_status = "error"
                try {
                  this.error_form_name.msg = error.response.data
                } catch (error) {
                  this.error_form_name.msg = "Unexpected error"
                }
              })
          }
          else {
            this.error_form_name.is_error = false
            this.error_form_name.fetch_status = "ok"
          }
        })
        .catch(error => {
          this.error_form_name.is_error = true
          this.error_form_name.fetch_status = "error"
          try {
            this.error_form_name.msg = error.response.data
          } catch (error) {
            this.error_form_name.msg = "Unexpected error"
          }
        })
    },
    delete_recipe() {
      if (!window.confirm("Voulez-vous vraiment supprimer la recette ?"))
        return
      this.error_form_name.fetch_status = "loading"
      api_call()
        .delete("/recipes/" + this.recipe.id)
        .then(reponse => {
          this.error_form_name.is_error = false
          this.error_form_name.fetch_status = "ok"
          window.location.href = "/admin/recettes"
        })
        .catch(error => {
          this.error_form_name.is_error = true
          this.error_form_name.fetch_status = "error"
          try {
            this.error_form_name.msg = error.response.data
          } catch (error) {
            this.error_form_name.msg = "Unexpected error"
          }
        })
    },
    add_step() {
      event.preventDefault()
      this.error_new_step.fetch_status = "loading"
      api_call()
        .post("/recipes/" + this.recipe.id + "/steps", {
          name: this.new_step.name
        })
        .then(response => {
          this.error_new_step.is_error = false
          this.error_new_step.fetch_status = "ok"
          this.new_step.name = ""
          this.update()
        })
        .catch(error => {
          this.error_new_step.is_error = true
          this.error_new_step.fetch_status = "error"
          try {
            this.error_new_step.msg = error.response.data
          } catch (error) {
            this.error_new_step.msg = "Unexpected error"
          }
        })
    }
  },
  watch: {
    need_update: {
      handler(val, oldval) {
        this.update(true)
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
#recipe-edit-page>* {
  margin-bottom: 10px;
}
#recipe-edit-page-return-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
#recipe-edit-page-return-row>h1 {
  margin-left: 15px;
}
#recipe-edit-page-return-row>.return-button>* {
  margin-right: 3px;
  margin-bottom: 3px;
}
.recipe-image-edit-page {
  min-height: 20vh;
  background-position: 50% 50%;
  background-size: cover;
}
</style>