<template>
  <b-row class="full-width-row">
    <b-col v-if="!isReverse" class="image-column" :style="imageColStyle"></b-col>
    <b-col class="text-column" :style="textColStyle">
      <div>
        <slot />
        <b-button v-if="!noLink" class="text-column-btn" :style="btnStyle" :href="href">En savoir plus</b-button>
      </div>
    </b-col>
    <b-col v-if="isReverse" class="image-column" :style="imageColStyle"></b-col>
  </b-row>
</template>

<script>
import { is_phone } from "@/plugins/utils.js"
export default {
  name: 'ImageAndTextRow',
  props: {
    image: {
      type: String,
      required: true,
    },
    href: {
      type: String,
    },
    colorReverse: {
      type: Boolean
    },
    reverse: {
      type: Boolean
    },
    noLink: {
      type: Boolean
    },
  },
  computed: {
    textColStyle() {
      if (this.colorReverse) {
        return {
          color: "var(--color-beige-light)",
          backgroundColor: "var(--color-green-dark-2)",
        }
      }
      return {
        color: "var(--color-black)",
        backgroundColor: "var(--color-beige-base)",
      }
    },
    imageColStyle() {
      return {
        backgroundImage: "url(" + this.image + ")",
      }
    },
    btnStyle() {
      if (this.colorReverse) {
        return {
          color: "var(--color-black)",
          backgroundColor: "var(--color-beige-dark)",
        }
      }
      return {
        color: "var(--color-brown-white)",
        backgroundColor: "var(--color-brown-dark)",
      }
    },
    isReverse() {
      return is_phone() || this.reverse
    }
  },
}
</script>

<style scoped>
.text-column {
  padding: 0;
  width: 50vw;
  min-height: 30rem;
}
.image-column {
  padding: 0;
  width: 50vw;
  min-height: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;

  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  align-content: flex-end;
}
@media only screen and (max-width: 768px) {  /* phones only */
  .text-column {
    min-width: 100vw;
    min-height: 15rem;
  }
  .image-column {
    min-width: 100vw;
  }
}
.text-column>div {
  margin: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.text-column-btn {
  width: 15rem;
  height: 4rem;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.3rem;
  border: none;
  box-shadow: 0.2rem 0.1rem 1rem var(--color-brown-dark);
  font-size: var(--size-large-text);
}
.text-column-btn:hover {  /* buttons */
  box-shadow: 0.2rem 0.1rem 2rem var(--color-brown-dark);
}
.text-column-btn:active {
  transform: translateY(2px);
}

.text-column p {
  text-align: center;
  font-size: var(--size-large-text);
}
</style>