<template>
  <div class="master-title2">
    <h3><slot /></h3>
  </div>
</template>

<script>
export default {
  name: "MasterTitle2",
};
</script>

<style scoped>
.master-title2>h3 {
  margin: 0.5rem;
  text-align: center;
  text-decoration-line: underline;
  text-decoration-color: var(--color-green-base);  
  text-decoration-style: solid;
  text-decoration-skip-ink: initial;
  text-decoration-thickness: 5px; 
}

</style>