<template>
  <div class="master-title">
    <h1 :style="masterTitleStyle"><slot /></h1>
  </div>
</template>

<script>
export default {
  name: "MasterTitle",
  props: {
    color: {
      type: String,
      required: false,
      default: "black",
    },
    sizeFactor: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  computed: {
    masterTitleStyle() {
      return {
        color: this.color,
        fontSize: (2.5 * this.sizeFactor).toString() + "rem",
      }
    },
  },
};
</script>

<style scoped>
.master-title>h1 {
  margin: 2rem;
  text-align: center;
  text-decoration-line: underline;
  text-decoration-color: var(--color-green-dark);  
  text-decoration-style: solid;
  text-decoration-skip-ink: initial;
  text-decoration-thickness: 5px; 
}

</style>