<template>
  <b-row class="justify-content-md-center" style="margin-top: 1rem;">
    <b-col cols="12" md="12" class="d-flex justify-content-center">
      <img :src="image" alt="Image" class="centered-image" :style="imageStyle"/>
    </b-col>
  </b-row>
</template>

<script>
import { is_phone } from '@/plugins/utils.js'

export default {
  name: 'PhotoText',
  props: {
    image: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: false,
      default: "",
    },
    height: {
      type: String,
      required: false,
      default: "",
    },
  },
  data () {
    return {
      is_phone: is_phone,
    }
  },
  computed: {
    imageStyle() {
      if (this.is_phone()) {
        return {
          width: "90%",
        }
      }
      if (this.width != "") {
        return {
          width: this.width,
        }
      }
      if (this.height != "") {
        return {
          height: this.height,
        }
      }
      return {
        width: "50%",
      }
    },
  },
}
</script>

<style scoped>
.centered-image {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}
</style>