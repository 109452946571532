<template>
	<fragment>
    <b-row>
      <b-col id="datepicker-col">
        <div>
          <input class="tb-checkbox" type="checkbox" v-model="start_date_check"/>
          <b-datepicker
            class="date-selection"
            v-model="start_date"
            :value-as-date="true"
            start-weekday=1
            locale="fr-FR"
            calendar-width="100%"
            menu-class="w-100"
            :disabled="start_date_check == false"
          />
        </div>
        <div>
          <input class="tb-checkbox" type="checkbox" v-model="end_date_check"/>
          <b-datepicker
            class="date-selection"
            v-model="end_date"
            :value-as-date="true"
            start-weekday=1
            locale="fr-FR"
            calendar-width="100%"
            menu-class="w-100"
            :disabled="end_date_check == false"
          />
        </div>
        <div>
          <b-button v-on:click="stats_interval" :pressed.sync="auto_update" variant="secondary">
            <b-icon icon="arrow-repeat"></b-icon>
          </b-button>
          <b-button variant=secondary v-on:click="stats_interval">Valider</b-button>
        </div>
      </b-col>
    </b-row>


    <b-row>
      <template v-if="stats">
        <table id="titleTable">
          <tr>
            <td>Stats beetween <b>{{ start_date.toLocaleDateString("fr-FR") }}</b> and <b>{{ end_date.toLocaleDateString("fr-FR") }}</b></td>
          </tr>
        </table>
        <div id="topTable">
          <table>
            <tr>
              <td>Nb transactions</td>
              <td>{{ stats.nb_transactions }}</td>
            </tr>
            <tr>
              <td>Total brut</td>
              <td>{{ stats.total_brut }}</td>
            </tr>
            <tr>
              <td>Total net</td>
              <td>{{ stats.total_net }}</td>
            </tr>
          </table>

          <table>
            <tr>
              <td>Nb repas</td>
              <td>{{ stats.nb_repas }}</td>
            </tr>
            <tr>
              <td>Nb café</td>
              <td>{{ stats.nb_cafe }}</td>
            </tr>
            <tr>
              <td>Nb cookies</td>
              <td>{{ stats.nb_cookie }}</td>
            </tr>
            <tr>
              <td>Nb jus</td>
              <td>{{ stats.nb_jus }}</td>
            </tr>
          </table>
        </div>

        <hr/>
        <template v-if="Object.keys(stats.categories).length > 0">
          <table>
            <tr>
              <th>Categorie</th>
              <th>Nb vendues</th>
            </tr>

            <tr v-for="(val, key) in stats.categories" :key="key">
              <td>{{ key }}</td>
              <td>{{ val }}</td>
            </tr>
          </table>

          <hr/>
        </template>
      </template>

      <template v-if="last_transaction">
        <table>
          <tr>
            <td>Last transaction date</td>
            <td>{{ last_transaction.time.slice(0, 19) }}</td>
          </tr>
          <tr>
            <td>Last transaction code</td>
            <td>{{ last_transaction.transaction_code }}</td>
          </tr>
          <tr>
            <td>Last transaction price</td>
            <td>{{ last_transaction.amount_brut }}</td>
          </tr>
        </table>

      </template>
    </b-row>
	</fragment>
</template>

<script>
import { to_euro, api_call } from '@/plugins/utils'

export default {
	name: 'CompteDate',
	components: {
	},
  data() {
    return {
			start_date_check: true,
			end_date_check: true,
			start_date: new Date(),
			end_date: new Date(),
			stats: null,
			last_transaction: null,
			auto_update: false,
			interval: null,
    }
  },
	mounted() {
		this.stats_interval()
		this.interval = setInterval(this.recurentCallback, this.$store.state.update_sumup_interval);
		this.recurentCallback();
	},
  destroyed() {
    clearInterval(this.interval)
  },
	methods: {
    test () {
      console.log(this.auto_update)
    },
		recurentCallback: function () {
			if (this.$store.state.is_connected && this.auto_update) {
				this.stats_interval()
			}
		},
		stats_interval: function() {
			console.log("update data table")
			let start = 'start_date=' + this.start_date.toLocaleDateString('fr-FR').replaceAll("/", "_")
			let end = 'end_date=' + this.end_date.toLocaleDateString('fr-FR').replaceAll("/", "_")
			let url = ''
			if (this.start_date_check) {
				url += '?' + start
			}
			if (this.start_date_check) {
				if (url == '') {
					url += '?'
				}
				else {
					url += '&'
				}
				url += end
			}
			api_call()
				.get('/sumup/interval' + url)
				.then(response => {
					this.stats = response.data
					this.stats.total_brut = to_euro(this.stats.total_brut)
					this.stats.total_net = to_euro(this.stats.total_net)
				})
				.catch(error => {
					if (error.response) {
						console.log("error in /stats/interval\n"
							+ error.response.status + '\n'
							+ error.response.data)
					}
				})
			api_call()
				.get('/sumup/get/last')
				.then(response => {
					if (Object.keys(response.data).length > 0) {
						this.last_transaction = response.data
						this.last_transaction.amount_brut = to_euro(this.last_transaction.amount_brut)
						this.last_transaction.amount_net = to_euro(this.last_transaction.amount_net)
					}
					else {
						this.last_transaction = null
					}
				})
				.catch(error => {
					if (error.response) {
						console.log("error in /transactions/get/last\n"
							+ error.response.status + '\n'
							+ error.response.data)
					}
				})
		},
	}
}
</script>

<style scoped>
#datepicker-col {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5px;
  margin-bottom: 15px;
  flex-wrap: wrap;
}
#datepicker-col>div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-right: 15px;
}
#datepicker-col>div>* {
  margin-right: 5px;
  margin-top: 5px;
}

.date-selection[aria-disabled] {
  background-color: var(--disabled-color) !important;
}

#titleTable {
	margin-left: 10px;
	margin-right: 10px;
}

#topTable {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
  flex-wrap: wrap;
}

#topTable>table {
	margin: 10px;
}
</style>
