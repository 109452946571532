import { render, staticRenderFns } from "./Le_projet.vue?vue&type=template&id=3696883d&scoped=true&"
import script from "./Le_projet.vue?vue&type=script&lang=js&"
export * from "./Le_projet.vue?vue&type=script&lang=js&"
import style0 from "./Le_projet.vue?vue&type=style&index=0&id=3696883d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3696883d",
  null
  
)

export default component.exports