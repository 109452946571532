<template>
  <b-col>
    <b-form @submit="add_media" class="form-base-class">
      <!-- forms values -->
      <b-form-group label="Nom de l'article:">
        <b-form-input v-model="new_media.name" required></b-form-input>
      </b-form-group>
      <b-form-group label="Date:">
        <b-form-datepicker
          v-model="new_media.date"
          :value-as-date="true"
          start-weekday=1
          locale="fr-FR"
          calendar-width="100%"
          menu-class="w-100"
          required
        />
      </b-form-group>
      <b-form-group label="Lien:">
        <b-form-input v-model="new_media.link"></b-form-input>
      </b-form-group>
      <b-form-group label="Article important ?">
        <b-button :pressed.sync="new_media.is_important" variant="secondary">
          <fragment v-if="new_media.is_important">{{ "Important" }}</fragment>
          <fragment v-else>{{ "Secondaire" }}</fragment>
        </b-button>
      </b-form-group>
      <b-form-group label="Image :" style="max-width: 50%; width: 50%;">
        <b-form-file v-model="media_image" accept="image/jpeg, image/png" plain></b-form-file>
      </b-form-group>
      <!-- validation buttons -->
      <div class="form-validate-buttons">
        <b-button type="submit" variant="success">
          <b-icon icon="plus"/>Ajouter l'article
        </b-button>
      </div>
      <!-- errors -->
      <p class="form-error-msg" v-if="error.is_error">{{ error.msg }}</p>
    </b-form>
  </b-col>
</template>

<script>
import { api_call, date_to_str } from '@/plugins/utils.js'

export default {
  name: 'MediaAdd',
  data () {
    return {
      new_media: {
        name: "",
        date: new Date(),
        link: "",
        is_important: false,
      },
      media_image: null,
      error: {
        is_error: false,
        msg: null,
      },
    }
  },
  mounted() {
  },
  props: {
    updater: {
      type: Object,
      required: true,
    },
  },
  methods: {
    reset_form () {
      this.new_media.name = ""
      this.new_media.date = new Date()
      this.new_media.link = ""
      this.new_media.is_important = false
    },
    add_media (event) {
      event.preventDefault()
      api_call()
        .post("/media", {
          name: this.new_media.name,
          date: date_to_str(this.new_media.date),
          link: this.new_media.link,
          is_important: this.new_media.is_important,
        })
        .then(response => {
          console.log("Media added")
          if (this.media_image) {
            let form_image = new FormData();
            form_image.append('file', this.media_image);
            api_call({ 'Content-Type': 'multipart/form-data' })
              .post("/media/" + response.data.id + "/image", form_image)
              .then(response => {
                this.error.is_error = false
                this.error.fetch_status = "ok"
                this.updater.update = !this.updater.update
                this.reset_form()
                this.$router.go(0)
              })
              .catch(error => {
                this.error.is_error = true
                this.error.fetch_status = "error"
                try {
                  this.error.msg = error.response.data
                } catch (error) {
                  this.error.msg = "Unexpected error"
                }
              })
          }
          else {
            this.error.is_error = false
            this.error.fetch_status = "ok"
            this.updater.update = !this.updater.update
            this.reset_form()
            this.$router.go(0)
          }
        })
        .catch(error => {
          console.log("Error while media adding")
          this.error.is_error = true
          this.error.msg = error.response.data
        })
    },
  },
}
</script>
