<template>
  <b-container>
    <faire-un-don :fullHeight="true" />
    <tb-footer />
  </b-container>
</template>

<script>
import FaireUnDon from '@/components/association/FaireUnDon.vue'
import TbFooter from '@/components/utils/TbFooter.vue'

export default {
  name: 'Faire_un_don',
  components: {
    FaireUnDon,
    TbFooter,
  },
  mounted () {
  },
}
</script>

<style>
</style>
