<template>
  <b-row class="justify-content-md-center">
    <b-col lg=10 align="center">
      <master-title>Documents</master-title>
      <div class="buttons-file-download">
        <div class="tb-btns">
          <b-button href="assets/documents/Projet associatif Tim & Bastien.pdf" download>
            <font-awesome-icon
              style="color: var(--color-beige-base);"
              size="2x"
              icon="fa-solid fa-download"
            />
            Projet associatif
          </b-button>
          <b-button href="assets/documents/Reglement interieur Tim & Bastien.pdf" download>
            <font-awesome-icon
              style="color: var(--color-beige-base);"
              size="2x"
              icon="fa-solid fa-download"
            />
            Règlement intérieur
          </b-button>
          <b-button href="assets/documents/Statuts association Tim & Bastien.pdf" download>
            <font-awesome-icon
              style="color: var(--color-beige-base);"
              size="2x"
              icon="fa-solid fa-download"
            />
            Statuts
          </b-button>
        </div>
        <!-- <b-button variant="outline-secondary" size="lg" href="assets/documents/Projet associatif Tim & Bastien.pdf" download><b-icon icon="file-earmark-text"/> Projet associatif</b-button>
        <b-button variant="outline-secondary" size="lg" href="assets/documents/Reglement interieur Tim & Bastien.pdf" download><b-icon icon="file-earmark-text"/> Règlement intérieur</b-button>
        <b-button variant="outline-secondary" size="lg" href="assets/documents/Statuts association Tim & Bastien.pdf" download><b-icon icon="file-earmark-ruled"/> Statuts</b-button> -->
      </div>
      <master-title-2> Rapport d'activité pour l'année 2023</master-title-2>
      <div class="buttons-file-download">
        <div class="tb-btns">
          <b-button href="assets/documents/rapport_activite/2023/Rapport d'activités 2023 - Tim & Bastien.pdf" download>
            <font-awesome-icon
              style="color: var(--color-beige-base);"
              size="2x"
              icon="fa-solid fa-download"
            />
            Rapport moral 2023
          </b-button>
          <b-button href="assets/documents/rapport_activite/2023/Bilan financier 2023 - Tim & Bastien.pdf" download>
            <font-awesome-icon
              style="color: var(--color-beige-base);"
              size="2x"
              icon="fa-solid fa-download"
            />
            Bilan financier 2023
          </b-button>
        </div>
        <!-- <b-button variant="outline-secondary" size="lg" href="assets/documents/rapport_activite/2023/Rapport d'activités 2023 - Tim & Bastien.pdf" download><b-icon icon="file-earmark-text"/> Rapport moral 2023</b-button>
        <b-button variant="outline-secondary" size="lg" href="assets/documents/rapport_activite/2023/Bilan financier 2023 - Tim & Bastien.pdf" download><b-icon icon="file-earmark-ruled"/> Bilan financier 2023</b-button> -->
      </div>
      <master-title-2> Rapport d'activité pour la période février 2022 - décembre 2022</master-title-2>
      <div class="buttons-file-download">
        <div class="tb-btns">
          <b-button href="assets/documents/rapport_activite/2022/Rapport d'activités 2022 - Tim & Bastien.pdf" download>
            <font-awesome-icon
              style="color: var(--color-beige-base);"
              size="2x"
              icon="fa-solid fa-download"
            />
            Rapport moral 2022
          </b-button>
          <b-button href="assets/documents/rapport_activite/2022/Bilan financier 2022 - Tim & Bastien.pdf" download>
            <font-awesome-icon
              style="color: var(--color-beige-base);"
              size="2x"
              icon="fa-solid fa-download"
            />
            Bilan financier 2022
          </b-button>
        </div>
        <!-- <b-button variant="outline-secondary" size="lg" href="assets/documents/rapport_activite/2022/Rapport d'activités 2022 - Tim & Bastien.pdf" download><b-icon icon="file-earmark-text"/> Rapport moral 2022</b-button>
        <b-button variant="outline-secondary" size="lg" href="assets/documents/rapport_activite/2022/Bilan financier 2022 - Tim & Bastien.pdf" download><b-icon icon="file-earmark-ruled"/> Bilan financier 2022</b-button> -->
      </div>
      <master-title-2> Rapport d'activité pour la période février 2021 - février 2022</master-title-2>
      <div class="buttons-file-download">
        <div class="tb-btns">
          <b-button href="assets/documents/rapport_activite/2021/Rapport Moral 2022 - Tim & Bastien.pdf" download>
            <font-awesome-icon
              style="color: var(--color-beige-base);"
              size="2x"
              icon="fa-solid fa-download"
            />
            Rapport moral 2021
          </b-button>
          <b-button href="assets/documents/rapport_activite/2021/Bilan financier feb 2021 - feb 2022 Tim & Bastien.pdf" download>
            <font-awesome-icon
              style="color: var(--color-beige-base);"
              size="2x"
              icon="fa-solid fa-download"
            />
            Bilan financier 2021
          </b-button>
        </div>
        <!-- <b-button variant="outline-secondary" size="lg" href="assets/documents/rapport_activite/2021/Rapport Moral 2022 - Tim & Bastien.pdf" download><b-icon icon="file-earmark-text"/> Rapport moral 2021</b-button>
        <b-button variant="outline-secondary" size="lg" href="assets/documents/rapport_activite/2021/Bilan financier feb 2021 - feb 2022 Tim & Bastien.pdf" download><b-icon icon="file-earmark-ruled"/> Bilan financier 2021</b-button> -->
      </div>
    </b-col>
  </b-row>
</template>

<script>
import MasterTitle from '@/components/utils/MasterTitle.vue'
import MasterTitle2 from '@/components/utils/MasterTitle2.vue'
import { is_phone } from '@/plugins/utils.js'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

library.add(faDownload)

export default {
  name: 'Documents',
  components: {
    MasterTitle,
    MasterTitle2,
  },
  data () {
    return {
      is_phone: is_phone,
    }
  },
  mounted () {
  },
}
</script>

<style scoped>
.buttons-file-download {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.buttons-file-download>* {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
