<template>
  <div>
    <b-container v-if="recipe" class="recipe-container">
      <b-row class="recipe-row">
        <b-col id="recipe-view-first-row">
          <div class="return-button">
            <b-button variant=outline-secondary v-on:click="go_back()"><b-icon icon="caret-left" /></b-button>
            <!-- <b-button variant=outline-secondary href="/recettes/"><b-icon icon="caret-left" /></b-button> -->
            <b-button variant=outline-secondary v-if="$store.state.is_admin" :href="'/admin/recettes/' + recipe.id"><b-icon icon="pencil" /></b-button>
          </div>
          <h1 class="tb-title">{{ recipe.name }}</h1>
        </b-col>
      </b-row>
      <b-row class="recipe-row">
        <b-col>
          <div class="recipe-image-view-page" v-bind:style="{ backgroundImage: 'url(' + this.recipe.image_url + ')' }"></div>
        </b-col>
      </b-row>
      <b-row class="recipe-row">
        <b-col>
          {{ recipe.source }}
        </b-col>
      </b-row>
      <b-row class="recipe-row">
        <b-col class="nb-people-selection">
          <p style="text-align: right;">Nombre de personnes :</p>
          <b-form-input type="number" :min="nb_pers_limit.min" :max="nb_pers_limit.max" v-model="$store.state.recipes_nb_people.nb_people" v-on:keyup="update_recipe()" @change="update_recipe()"/>
        </b-col>
      </b-row>
      <b-row class="recipe-row justify-content-md-center">
        <b-col lg=4 v-if="recipe.time_preparation > 0">
          <div style="text-align: center;">Préparation : {{ recipe.time_preparation_str }}</div>
        </b-col>
        <b-col lg=4 v-if="recipe.time_resting > 0">
          <p style="text-align: center;">Temps de repos : {{ recipe.time_resting_str }}</p>
        </b-col>
        <b-col lg=4 v-if="recipe.time_cooking > 0">
          <p style="text-align: center;">Temps de cuisson : {{ recipe.time_cooking_str }}</p>
        </b-col>
      </b-row>
      <b-row class="recipe-row justify-content-md-center">
        <b-col lg=8 class="tb-rounded-border-no-phone" style="margin-bottom: 15px;">
          <h2 class="tb-title-2">Ingrédients pour {{ computed_nb_people }}</h2>
          <div v-for="(step, step_idx) in recipe.steps" :key="step_idx">
            <div v-if="step.ingredients.length > 0">
              <b>{{ step.name }}</b>
              <div v-for="(ingr, ingr_idx) in step.ingredients" :key="ingr_idx" class="ingr-element">
                &emsp;&emsp;&emsp;<input type="checkbox">&nbsp;{{ ingr.name }}<fragment v-if="ingr.str != ''">: {{ ingr.str }}</fragment><div class="ingr-other-units">&nbsp;{{ ingr.other_units_str }}</div>
              </div>
            </div>
          </div>
          <p></p>
          <p style="font-size: smaller;">
            <b>Allergènes : </b><fragment v-if="allergenics_str.normal != '' || allergenics_str.trace_de != ''">{{ allergenics_str.normal }}<fragment v-if="allergenics_str.trace_de !=''"><fragment v-if="allergenics_str.normal !=''">, </fragment>trace de : {{ allergenics_str.trace_de }}</fragment></fragment>
            <fragment v-else>Aucun</fragment>
          </p>
        </b-col>
      </b-row>
      <fragment v-for="(step, step_idx) in recipe.steps" :key="step_idx">
        <fragment v-if="step.content != ''">
          <b-row class="recipe-row justify-content-md-center recipe-step-content">
            <b-col lg=12 class="tb-rounded-border-no-phone" style="margin-bottom: 15px;" @click="expand_step(step_idx)">
              <hr v-if="is_phone()"/>
              <p style="text-align: center;"><b>{{ step.name }}</b></p>
              <div v-if="step.expanded" class="recipe-step-ingr">
                <div v-for="(ingr, ingr_idx) in step.ingredients" :key="ingr_idx" class="tb-rounded-border ingr-element">
                  {{ ingr.name }}<fragment v-if="ingr.str != ''">: {{ ingr.str }}</fragment><div class="ingr-other-units">&nbsp;{{ ingr.other_units_str }}</div>
                </div>
              </div>
              <ul>
                <li v-for="(content, content_idx) in step.content_split" :key="content_idx">{{ content }}<br></li>
              </ul>
            </b-col>
          </b-row>
        </fragment>
      </fragment>
    </b-container>
    <loading-page v-else/>
  </div>
</template>

<script>
import { api_call, go_back, get_image_url, is_phone } from '@/plugins/utils.js'
import LoadingPage from '@/components/utils/LoadingPage.vue'

export default {
  components: {
    LoadingPage,
  },
  name: 'RecipesView',
  props: {
    recipe_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      go_back: go_back,
      is_phone: is_phone,
      recipe: null,
      allergenics_str: {
        normal: '',
        trace_de: '',
      },
      computed_nb_people: null,
      error: { is_error: false, msg: null, fetch_status: "" },
      nb_pers_limit: { min: 1, max: 8000000000 },
    }
  },
  mounted() {
    if (this.$store.state.recipes_nb_people.recipe_id != this.recipe_id) {
      this.$store.commit('recipe_nb_people_set_nb', null)
    }
    this.update_recipe()
  },
  methods: {
    update_recipe() {
      var nb = parseFloat(this.$store.state.recipes_nb_people.nb_people)
      if (this.$store.state.recipes_nb_people.nb_people != null) {
        if (isNaN(nb) || nb < this.nb_pers_limit.min || nb > this.nb_pers_limit.max) {
          return
        }
      }
      this.error.fetch_status = "loading"
      var req = "/recipes/" + this.recipe_id
      if (this.$store.state.recipes_nb_people.nb_people != null) {
        this.$store.commit('recipe_nb_people_set_nb', this.$store.state.recipes_nb_people.nb_people)
        req += "/for/" + this.$store.state.recipes_nb_people.nb_people
      }
      api_call()
        .get(req)
        .then(response => {
          var rec = response.data
          rec.image_url = get_image_url("/recipes", rec.id)
          for (var i = 0; i < rec.steps.length; i++) {
            rec.steps[i].content_split = rec.steps[i].content.split("\n")
            rec.steps[i].expanded = true
            for (var j = 0; j < rec.steps[i].ingredients.length; j++) {
              rec.steps[i].ingredients[j].other_units_str = ""
              if (rec.steps[i].ingredients[j].other_units.length > 0) {
                // rec.steps[i].ingredients[j].other_units_str += "("
                for (var k = 0; k < rec.steps[i].ingredients[j].other_units.length; k++) {
                  if (k >= 1)
                    rec.steps[i].ingredients[j].other_units_str += ", "
                  rec.steps[i].ingredients[j].other_units_str += rec.steps[i].ingredients[j].other_units[k]
                }
                // rec.steps[i].ingredients[j].other_units_str += ")"
              }
            }
          }
          if (this.$store.state.recipes_nb_people.nb_people == null) {
            if (this.$store.state.recipes_nb_people.recipe_id != rec.id) {
              this.$store.commit('recipe_nb_people_set_nb', rec.nb_people_default)
              this.$store.commit('recipe_nb_people_set_id', rec.id)
            }
          }
          this.allergenics_str.trace_de = ""
          this.allergenics_str.normal = ""
          for (const [key, value] of Object.entries(rec.allergenics)) {
            if (value.is_trace_de) {
              if (this.allergenics_str.trace_de != "")
                this.allergenics_str.trace_de += ", "
              this.allergenics_str.trace_de += key
            }
            else {
              if (this.allergenics_str.normal != "")
                this.allergenics_str.normal += ", "
              this.allergenics_str.normal += key
            }
          }
          this.recipe = rec
          this.computed_nb_people = this.$store.state.recipes_nb_people.nb_people
          this.error.is_error = false
          this.error.fetch_status = "ok"
        })
        .catch(error => {
          this.error.fetch_status = "error"
          this.error.is_error = true
          try {
            this.error.msg = error.response.data
          } catch (error) {
            this.error.msg = "Unexpected error"
          }
        })
    },
    expand_step(step_idx) {
      // this.recipe.steps[step_idx].expanded = !this.recipe.steps[step_idx].expanded
    },
  },
}
</script>

<style>
@media (min-width: 992px) { 
  .recipe-container {
    max-width: 768px !important;
  }
}
@media (min-width: 1200px) { 
  .recipe-container {
    max-width: 1000px !important;
  }
}

#recipe-view-first-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
#recipe-view-first-row>.return-button>* {
  margin-right: 3px;
  margin-bottom: 3px;

}
#recipe-view-first-row>h1 {
  margin-left: 15px;
}
.recipe-image-view-page {
  min-height: 70vh;
  background-position: 50% 50%;
  background-size: cover;
}
.recipe-row {
  margin-bottom: 5px;
}
.nb-people-selection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.nb-people-selection>p {
  margin: 0;
}
.nb-people-selection>input {
  max-width: 30%;
  margin-left: 10px;
}
@media only screen and (max-width: 768px) {  /* phones only */
  .nb-people-selection>input {
    max-width: 100%;
    margin-left: 0;
  }
}

.recipe-step-ingr {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.recipe-step-ingr>div {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 5px;
  margin: 2px;
}
.ingr-element {
  display: flex;
  align-items: center;
}
.ingr-other-units {
  color: gray;
  font-style: italic;
  font-size: 0.9rem;
  align-self: flex-end;
}
</style>
