<template>
  <div>
    <b-container>
      <b-row>
        <b-col lg=6 class="input-container">
          <api-call-status :status="search_status" icon_ok="search"/>
          <b-form-input type="search" v-model="search_string" placeholder="search..." v-on:keyup="search_recipes()" class="border-dark"></b-form-input>
        </b-col>
        <b-col lg=6 class="input-container">
          <dropdown-select-multiple
            v-if="allergenics_list"
            title="Allergies"
            :dropdown_list="allergenics_list"
            :selected_values="chosen_allergenics"
            :reset_button="true"
            :select_all_button="false"
          ></dropdown-select-multiple>
          <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
              <div v-for="(al, al_idx) in chosen_allergenics" :key="al_idx">{{ al }}<fragment v-if="al_idx < chosen_allergenics.length - 1">,&nbsp;</fragment></div>
          </div>
          <!-- <fragment v-for="(al, al_idx) in chosen_allergenics" :key="al_idx"><fragment v-if="al_idx > 0">, </fragment>{{ al }}</fragment> -->
        </b-col>
      </b-row>
      <b-row class="recipes-row" v-if="recipes">
        <div class="recipes">
          <div class="recipe-card" v-for="(recipe, idx) in recipes" :key="idx" v-on:click="open_recipe(recipe.id)">
            <div class="recipe-image" v-bind:style="{ backgroundImage: 'url(' + recipe.image_url + ')' }">
              <div class="recipe-name"><b>{{ recipe.name }}</b></div>
            </div>
          </div>
        </div>
      </b-row>
      <loading-page v-else/>
      <tb-footer />
    </b-container>
  </div>
</template>

<script>
import { api_call, get_image_url } from '@/plugins/utils.js'
import DropdownSelectMultiple from '@/components/utils/DropdownSelectMultiple.vue'
import ApiCallStatus from '@/components/utils/ApiCallStatus.vue'
import LoadingPage from '@/components/utils/LoadingPage.vue'
import TbFooter from '@/components/utils/TbFooter.vue'

export default {
  name: 'Recipes',
  components: {
    DropdownSelectMultiple,
    ApiCallStatus,
    LoadingPage,
    TbFooter,
  },
  data() {
    return {
      recipes: null,
      search_string: "",
      allergenics_list: null,
      chosen_allergenics: [],
      search_status: 'ok',
    }
  },
  mounted() {
    api_call()
      .get("/recipes/ingredients/allergenics")
      .then(allergenics_list => {
        this.allergenics_list = []
        for (var i = 0; i < allergenics_list.data.length; i++) {
          this.allergenics_list.push(allergenics_list.data[i].name)
        }
      })
    this.search_recipes()
  },
  methods: {
    search_recipes () {
      this.search_status = 'loading'
      api_call()
        .post("/recipes/search", {
          search: {
            search_data: this.search_string,
            published_only: true,
            allergenics: this.chosen_allergenics,
          }
        })
        .then(response => {
          this.recipes = response.data
          var img_width = parseFloat(getComputedStyle(document.documentElement).fontSize) * 16
          for (var i in this.recipes) {
            this.recipes[i].image_url = get_image_url("/recipes", this.recipes[i].id, img_width)
          }
          this.search_status = 'ok'
        })
        .catch(error => {
          this.search_status = 'ok'
        })
    },
    open_recipe(recipe_id) {
      window.location = "/recettes/" + recipe_id
    },
  },
  watch: {
    chosen_allergenics: function() {
      this.search_recipes()
    },
  },
}
</script>

<style>
.input-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}
.input-container>* {
  margin: 5px;
}

.recipes-row {
  padding: 0;
}
.recipes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.recipe-card {
  display: flex;
  flex-direction: column;
  margin: 0.3rem;
  padding: 1%;
  width: 16rem;
  height: 16rem;
  background-color: rgba(255, 255, 255, 0.144);
}
@media only screen and (max-width: 768px) {  /* phones only */
  .recipe-card {
    width: 8rem;
    height: 8rem;
  }
}
.recipe-card:hover {
  background-color: rgba(190, 190, 190, 0.445);
}

.recipe-image {
  min-height: 100%;
  max-height: 100%;
  min-width: 100%;
  max-width: 100%;
  background-position: 50% 50%;
  background-size: cover;
  align-content: flex-end;
}

.recipe-name {
  color: #000;
  display: flex;
  min-height: 15%;
  min-width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex: 0 auto;
  border-radius: 0px;
  background-color: rgb(193 193 193 / 56%);
  font-size: 15px;
  text-align: center;
  line-height: 15px;
}

.icons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 5px;
  height: 40px;
}
.icons>img {
  max-width: 30px;
  max-height: 30px;
}

</style>
