<template>
  <fragment>
    <!-- forms values -->
    <b-form-group label="Nom :">
      <b-form-input v-model="ingr.name" required></b-form-input>
    </b-form-group>
    <b-form-group label="Catégorie :">
      <b-form-input autocomplete="off" list="ingredient-categories" v-model="ingr.categorie.name" required></b-form-input>
      <datalist id="ingredient-categories">
        <option v-for="(cat, key) in categories_list" :key="key">{{ cat.name }}</option>
      </datalist>
    </b-form-group>
    <b-form-group label="Unité (g, L, etc.) :">
      <b-form-input v-model="ingr.unit" v-on:keyup="unit_updated(ingr.id)" required></b-form-input>
    </b-form-group>
      <b-form-group label="Autres unités :" v-if="ingr.all_possible_units.length > 0 && ingr.unit in UNITS_LIST">
      <b-form-checkbox
        v-for="(val, idx) in ingr.all_possible_units" :key="idx"
        v-model="ingr.all_possible_units[idx]"
        :value=true
        :unchecked-value=false
        @change="unit_checked(ingr.id)"
      >{{ UNITS_LIST[ingr.unit][idx].name }}</b-form-checkbox>
    </b-form-group>
    <b-form-group :label="'Poid par pièce (en ' + ingr.unit + ') :'" v-if="ingr.use_piece_weigth">
      <b-form-input type="number" v-model="ingr.piece_weight"></b-form-input>
    </b-form-group>
    <b-form-group>
      <dropdown-select-multiple
        v-if="allergenics_list"
        title="Allergènes"
        :dropdown_list="allergenics_list"
        :selected_values="ingr.chosen_allergenics"
        :reset_button="true"
        :select_all_button="false"
        v-model="need_update"
      ></dropdown-select-multiple>
      <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
          <div v-for="(al, al_idx) in ingr.chosen_allergenics" :key="al_idx">{{ al }}<fragment v-if="al_idx < ingr.chosen_allergenics.length - 1">,&nbsp;</fragment></div>
      </div>
    </b-form-group>
    <b-form-group>
      <dropdown-select-multiple
        v-if="allergenics_list"
        title="Allergènes 'trace de'"
        :dropdown_list="allergenics_list"
        :selected_values="ingr.chosen_allergenics_trace_de"
        :reset_button="true"
        :select_all_button="false"
        v-model="need_update"
      ></dropdown-select-multiple>
      <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
          <div v-for="(al, al_idx) in ingr.chosen_allergenics_trace_de" :key="al_idx">{{ al }}<fragment v-if="al_idx < ingr.chosen_allergenics_trace_de.length - 1">,&nbsp;</fragment></div>
      </div>
    </b-form-group>
  </fragment>
</template>

<script>
import DropdownSelectMultiple from '@/components/utils/DropdownSelectMultiple.vue'

export default {
  name: 'RecipesIngredientsForm',
  components: {
    DropdownSelectMultiple,
  },
  props: {
    ingr: {
      type: Object,
      required: true,
    },
    allergenics_list: {
      type: Array,
      required: true,
    },
    categories_list: {
      type: Array,
      required: true,
    },
    UNITS_LIST: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      need_update: { need_update: false },
    }
  },
  created() {
    this.unit_updated()
  },
  methods: {
    unit_updated(force_update=true) {
      if (this.ingr.unit in this.UNITS_LIST) {
        this.ingr.all_possible_units = Array(this.UNITS_LIST[this.ingr.unit].length).fill(false)
        for (var i = 0; i < this.ingr.others_units.length; i++) {
          if (this.ingr.others_units[i] < this.ingr.all_possible_units.length) {
            this.ingr.all_possible_units[this.ingr.others_units[i]] = true
          }
        }
      }
      else {
        this.ingr.all_possible_units = []
      }
      this.unit_checked(force_update)
    },
    unit_checked(force_update=true) {
      if (this.ingr.unit == 'g' && this.ingr.all_possible_units[2]) {  // pièce
        this.ingr.use_piece_weigth = true
      }
      else {
        this.ingr.use_piece_weigth = false
      }
      if (force_update) {
        this.$forceUpdate()
      }
    },
  },
  watch: {
    need_update: {
      handler(val, oldval) {
        this.$forceUpdate()
      },
      deep: true,
    },
  },
}
</script>
