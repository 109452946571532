<template>
  <b-container>
    <b-row>
      <b-table striped hover responsive
        :items="users"
        :fields="fields"
        label-sort-asc=""
        label-sort-desc=""
        label-sort-clear=""
        sort-by="is_admin"
        :sort-desc="true"
      >
        <!-- <template #cell(newsletter)="row">
          <b-form-checkbox :checked="row.newsletter" disabled></b-form-checkbox>
        </template>
        <template #cell(is_admin)="row">
          <b-form-checkbox :checked="row.is_admin" disabled></b-form-checkbox>
        </template> -->
        <template #cell(action)="row">
          <b-button size="sm" @click="deleteAccount(row)" variant="outline-danger" class="mr-2">
            Supprimer le compte
          </b-button>
        </template>
      </b-table>
    </b-row>
    <b-row>
      <h3>Liste de tous les mails</h3>
      <p>{{ list_mail_all }}</p>
      <h3>Liste de tous les mails (abonné a la newsletter)</h3>
      <p>{{ list_mail_newsletter }}</p>
    </b-row>
    <b-row>
      <b-col>
        <h2>Mails type</h2>
        <p style="font-family: 'Courier New', monospace;">
          Dans le contenu du mail, vous pouvez utiliser des balises HTML.<br/>
          Vous pouvez utiliser des élément relatif a l'utilisateur<br/>
          {user.username} -> le mail<br/>
          {user.firstname} {user.lastname} {user.profil} etc.<br/>
          <br/>
          Vous pouvez tester de vous auto envoyer les mails avec le bouton "Tester"
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h3>Nouvel adhérent (asso)</h3>
        <p>Mail envoyé a contact@timbastien.fr a la création d'un compte</p>
        <b-form-input
          v-model="mail.new_user_asso.title"
          style="margin-bottom: 0.5rem; font-family: 'Courier New', monospace;"
        ></b-form-input>
        <b-form-textarea
          v-model="mail.new_user_asso.content"
          rows="8"
          max-rows="50"
          style="margin-bottom: 0.5rem; font-family: 'Courier New', monospace;"
        ></b-form-textarea>
        <b-button size="sm" @click="update_mail('new_user_asso')" class="mr-2">Mettre a jour</b-button>
        <b-button size="sm" @click="reset_mail('new_user_asso')" variant="outline-danger" class="mr-2">Réinitialiser</b-button>

        <div style="margin-top: 1rem;">
          <b-form-input
            v-model="mail.new_user_asso.test_mail"
            placeholder="Mail de test"
            style="margin-top: 0.5rem;"
          ></b-form-input>
          <b-button size="sm" @click="send_mail('new_user_asso')" class="mr-2">Tester</b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h3>Nouvel adhérent (adhérent)</h3>
        <p>Mail envoyé au nouveau a la création d'un compte</p>
        <b-form-input
          v-model="mail.new_user_user.title"
          style="margin-bottom: 0.5rem; font-family: 'Courier New', monospace;"
        ></b-form-input>
        <b-form-textarea
          v-model="mail.new_user_user.content"
          rows="8"
          max-rows="50"
          style="margin-bottom: 0.5rem; font-family: 'Courier New', monospace;"
        ></b-form-textarea>
        <b-button size="sm" @click="update_mail('new_user_user')" class="mr-2">Mettre a jour</b-button>
        <b-button size="sm" @click="reset_mail('new_user_user')" variant="outline-danger" class="mr-2">Réinitialiser</b-button>

        <div style="margin-top: 1rem;">
          <b-form-input
            v-model="mail.new_user_user.test_mail"
            placeholder="Mail de test"
            style="margin-top: 0.5rem;"
          ></b-form-input>
          <b-button size="sm" @click="send_mail('new_user_user')" class="mr-2">Tester</b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h3>Renouvellement adhésion</h3>
        <p>Mail envoyé tous les ans a tous les adhérents</p>
        <b-form-input
          v-model="mail.renew_adhesion.title"
          style="margin-bottom: 0.5rem; font-family: 'Courier New', monospace;"
        ></b-form-input>
        <b-form-textarea
          v-model="mail.renew_adhesion.content"
          rows="8"
          max-rows="50"
          style="margin-bottom: 0.5rem; font-family: 'Courier New', monospace;"
        ></b-form-textarea>
        <b-button size="sm" @click="update_mail('renew_adhesion')" class="mr-2">Mettre a jour</b-button>
        <b-button size="sm" @click="reset_mail('renew_adhesion')" variant="outline-danger" class="mr-2">Réinitialiser</b-button>

        <div style="margin-top: 1rem;">
          <b-form-input
            v-model="mail.renew_adhesion.test_mail"
            placeholder="Mail de test"
            style="margin-top: 0.5rem;"
          ></b-form-input>
          <b-button size="sm" @click="send_mail('renew_adhesion')" class="mr-2">Tester</b-button>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { api_call, date_to_long_str, str_to_date } from '@/plugins/utils.js'

export default {
  name: 'AdminUserList',
  data() {
    return {
      fields: [
        {
          key: 'firstname',
          label: 'Prénom',
          sortable: true
        },
        {
          key: 'lastname',
          label: 'Nom',
          sortable: true
        },
        {
          key: 'username',
          label: 'Mail',
          sortable: true
        },
        {
          key: 'profil',
          label: 'Profil',
          sortable: true,
        },
        {
          key: 'age',
          label: 'Age',
          sortable: true,
        },
        {
          key: 'created_date_str',
          label: 'Membre depuis',
          sortable: true,
        },
        {
          key: 'last_connected_date_str',
          label: 'Derniere connection',
          sortable: true,
        },
        {
          key: 'newsletter',
          label: 'Newsletter',
          sortable: true,
        },
        {
          key: 'is_admin',
          label: 'Admin',
          sortable: true,
        },
        {
          key: 'action',
          label: 'Actions',
          sortable: true,
        },
      ],
      users: null,
      list_mail_all: "",
      list_mail_newsletter: "",

      mail: {
        new_user_asso: {test_mail: "contact@timbastien.fr", title: "", content: "..."},
        new_user_user: {test_mail: "contact@timbastien.fr", title: "", content: "..."},
        renew_adhesion: {test_mail: "contact@timbastien.fr", title: "", content: "..."},
      },
    }
  },
  mounted() {
    this.reload();
    api_call()
      .get("/mails/new_user_asso")
      .then(response => {
        this.mail.new_user_asso.title = response.data.title;
        this.mail.new_user_asso.content = response.data.content;
      })
    api_call()
      .get("/mails/new_user_user")
      .then(response => {
        this.mail.new_user_user.title = response.data.title;
        this.mail.new_user_user.content = response.data.content;
      })
    api_call()
      .get("/mails/renew_adhesion")
      .then(response => {
        this.mail.renew_adhesion.title = response.data.title;
        this.mail.renew_adhesion.content = response.data.content;
      })
  },
  methods: {
    reload() {
      api_call()
        .get("/login")
        .then(response => {
          this.users = response.data
          for (var user of this.users) {
            user.created_date_str = date_to_long_str(str_to_date(user.created_date_str))
            user.last_connected_date_str = date_to_long_str(str_to_date(user.last_connected_date_str))
            user.action = null;
          }
        })
      api_call()
        .get("/login/list_mails")
        .then(response => {
          this.list_mail_all = "";
          this.list_mail_newsletter = "";
          for (var user of response.data) {
            this.list_mail_all += user.mail + " ";
            if (user.newsletter) {
              this.list_mail_newsletter += user.mail + " ";
            }
          }
        })
    },
    deleteAccount(user) {
      if (confirm("Êtes-vous sur de vouloir supprimer ce compte ?")) {
        api_call()
          .delete("/login/" + user.item.username)
          .then(response => {
            this.reload();
          })
      }
    },
    update_mail(name) {
      api_call().post("/mails/" + name, {
        title: this.mail[name].title,
        content: this.mail[name].content,
      });
    },
    reset_mail(name) {
      if (confirm("Êtes-vous sur de vouloir réinitialiser le contenu du mail type ?")) {
        api_call()
          .delete("/mails/" + name)
          .then(response => {
            api_call()
              .get("/mails/" + name)
              .then(response => {
                this.mail[name] = response.data;
              })
          })
      }
    },
    send_mail(name) {
      this.update_mail(name)
      api_call().post("/mails/" + name + "/send", {to: this.mail[name].test_mail})
    },
  },
}
</script>