<template>
  <b-container>
    <documents />
    <tb-footer />
  </b-container>
</template>

<script>
import Documents from '@/components/association/Documents.vue'
import TbFooter from '@/components/utils/TbFooter.vue'

export default {
  name: 'DocumentsView',
  components: {
    Documents,
    TbFooter,
  },
  mounted () {
  },
}
</script>

<style>
</style>
