import Vue from 'vue'
import './plugins/axios'
import './plugins/utils.js'
import App from './App.vue'
import router from './router'
import store from './store'
import Fragment from 'vue-fragment'
import Datepicker from 'vuejs-datepicker'
import VueResource from 'vue-resource'
import VueSocketIOExt from "vue-socket.io-extended";
import { io } from "socket.io-client";
import Store from '@/store/index.js'
import Carousel3d from 'vue-carousel-3d';
import { BootstrapVue, IconsPlugin, NavbarPlugin } from "bootstrap-vue";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@/css/bootstrap-custom.scss'; 
import 'bootstrap/dist/js/bootstrap.min.js';
import '@/css/main.css';
import '@/css/text.css';
import '@/css/table.css';
import '@/css/form.css';

Vue.config.productionTip = false

Vue.use(Fragment.Plugin)
Vue.use(Datepicker)
Vue.use(VueResource)
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(NavbarPlugin);
Vue.use(Carousel3d)
const socket = io(Store.state.api_url, {
  // extraHeaders: {
  //   Authorization: `Bearer ${Store.state.bearer_token}`,
  // }
});
Vue.use(VueSocketIOExt, socket, { store });
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.http.options.crossOrigin = true
Vue.http.headers.common['Access-Control-Allow-Origin'] = '*'

new Vue({
  router,
  store,
  socket,
  render: h => h(App),
  sockets: {
    connect: function () {
      console.log('Vuejs socket connected.')
      store.state.socket.socket = socket
      store.state.socket.is_connected = true
    },
    disconnect: function () {
      console.log("Vuejs socket disconnected.")
      store.state.socket.is_connected = false
    },
  },
}).$mount('#app')

export { socket }