<template>
  <div class="loading-page-spinner" :style="cssVars">
    <div>
      <b-spinner></b-spinner>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingPage',
  props: {
    full_page: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    cssVars() {
      var result = {}
      if (!this.full_page) {
        result['position'] = 'relative'
        result['min-height'] = '7vh'
      }
      return result
    }
  },
}
</script>

<style scoped>
.loading-page-spinner>div {
  position: absolute;
  width: 5vh;
  height: 5vh;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: auto;
}
</style>