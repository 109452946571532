<template>
  <b-container>
    <b-row>
      <h1 class="tb-title">Compte</h1>
    </b-row>
    <compte-connect />
    <compte-date />
    <compte-table />
    <b-row style="height: 30px"></b-row>
  </b-container>
</template>

<script>
import { api_call } from '@/plugins/utils.js'
import CompteConnect from "@/components/compte/CompteConnect.vue"
import CompteTable from "@/components/compte/CompteTable.vue"
import CompteDate from "@/components/compte/CompteDate.vue"

export default {
  name: 'AdminCompte',
  components: {
    CompteConnect,
    CompteTable,
    CompteDate,
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  },
}
</script>

<style>
</style>
