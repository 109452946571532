<template>
  <b-container id="all-media">
    <b-row v-for="(val, key) in medias" :key="key">
      <media-edit :media_data="val" :updater="media_need_update"/>
    </b-row>
    <b-row>
      <media-add :updater="media_need_update"/>
    </b-row>
  </b-container>
</template>

<script>
import { api_call } from '@/plugins/utils.js'
import MediaAdd from '@/components/media/MediaAdd.vue'
import MediaEdit from '@/components/media/MediaEdit.vue'

export default {
  name: 'AdminMedia',
  components: {
    MediaAdd,
    MediaEdit,
  },
  data () {
    return {
      medias: null,
      media_need_update: {
        update: false
      },
    }
  },
  mounted () {
    this.update_media()
  },
  methods: {
    update_media() {
      api_call()
        .get("/media")
        .then(response => {
          this.medias = response.data
        })
    },
  },
  watch: {
    media_need_update: {
      handler(val, oldval) {
        this.update_media()
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
#all-media>* {
  margin-bottom: 10px;
}
</style>