<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-form v-if="new_ingr" @submit="add_ingredient(-1)" class="form-base-class form-full-width-items">
          <!-- forms values -->
          <recipes-ingredients-form :ingr="new_ingr" :allergenics_list="allergenics_list" :categories_list="categories_list" :UNITS_LIST="UNITS_LIST" />
          <!-- validation buttons -->
          <div class="form-validate-buttons">
            <b-button type="submit" variant="success">
              <b-icon icon="plus"/> Ajouter
            </b-button>
          </div>
          <!-- errors -->
          <p class="form-error-msg" v-if="error.is_error">{{ error.msg }}</p>
        </b-form>
        <loading-page :full_page="false" v-else/>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="input-container">
        <api-call-status :status="search_status" icon_ok="search"/>
        <b-form-input type="search" v-model="search_string" placeholder="Search... (* to search all)" v-on:keyup="search_ingredients()" class="border-dark"></b-form-input>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="accordion" role="tablist" v-if="ingredients">
          <!-- <table> -->
            <table v-for="(ingr, ingr_idx) in ingredients" :key="ingr_idx">
              <tr>
                <td>{{ ingr.name }}</td>
                <td class="cell-button" style="width:100px;">
                  <b-button v-b-toggle="ingr.id_collapse" v-on:click="force_update()"><b-icon icon="pencil"/></b-button>
                </td>
              </tr>
              <tr>
                <td colspan="2" class="form-div-table">
                  <b-collapse :id="ingr.id_collapse" accordion="ingredient-accordion">
                    <b-form @submit="edit_ingredient(ingr.id)" class="form-base-class form-full-width-items">
                      <!-- forms values -->
                      <recipes-ingredients-form :ingr="ingr" :allergenics_list="allergenics_list" :categories_list="categories_list" :UNITS_LIST="UNITS_LIST" />
                      <!-- validation buttons -->
                      <div class="form-validate-buttons">
                        <b-button type="submit" variant="success">
                          <b-icon icon="pencil"/> Valider
                        </b-button>
                        <b-button type="button" v-on:click="delete_ingredient(ingr.id)" variant="danger">
                          <b-icon icon="x-circle"/> Supprimer
                        </b-button>
                      </div>
                      <!-- errors -->
                      <p class="form-error-msg" v-if="error.is_error">{{ error.msg }}</p>
                    </b-form>
                  </b-collapse>
                </td>
              </tr>
            </table>
          <!-- </table> -->
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { api_call, get_simple_string } from '@/plugins/utils.js'
import RecipesIngredientsForm from '@/components/recipes/RecipesIngredientsForm.vue'
import ApiCallStatus from '@/components/utils/ApiCallStatus.vue'
import LoadingPage from '@/components/utils/LoadingPage.vue'

export default {
  name: 'RecipesIngredients',
  components: {
    RecipesIngredientsForm,
    ApiCallStatus,
    LoadingPage,
  },
  data () {
    return {
      ingredients: null,
      new_ingr: null,
      error: { is_error: false, msg: null, },
      categories_list: null,
      UNITS_LIST: null,
      allergenics_list: null,
      need_update: { need_update: false },
      search_status: 'ok',
      search_string: "",
    }
  },
  mounted() {
    api_call()
      .get("/recipes/ingredients/units_list")
      .then(units_list => {
        this.UNITS_LIST = units_list.data
        api_call()
          .get("/recipes/ingredients/allergenics")
          .then(allergenics_list => {
            this.allergenics_list = []
            for (var i = 0; i < allergenics_list.data.length; i++) {
              this.allergenics_list.push(allergenics_list.data[i].name)
            }
            api_call()
              .get("/recipes/ingredients/categories")
              .then(categories_list => {
                this.categories_list = categories_list.data
                this.update()
              })
          })
      })
  },
  methods: {
    force_update() {
      this.$forceUpdate()
    },
    update() {
      this.new_ingr = {
        id_collapse: "add-ingredient-in-list",
        id: -1,
        name: "",
        unit: "",
        categorie: { name: "" },
        others_units: [],
        piece_weight: 0,
        use_piece_weight: false,
        all_possible_units: [],
        chosen_allergenics: [],
        chosen_allergenics_trace_de: [],
      }
      this.search_ingredients()
    },
    search_ingredients() {
      if (this.search_string != "") {
        this.search_status = 'loading'
        var search_str = this.search_string
        if (search_str == "*") {
          search_str = ""
        }
        api_call()
          .post("/recipes/ingredients/search", {
            search: {
              name: search_str,
            },
          })
          .then(response => {
            this.ingredients = response.data
            for (var i = 0; i < this.ingredients.length; i++) {
              this.ingredients[i].id_collapse = "ingr-table-collapse-" + this.ingredients[i].id
              this.ingredients[i].all_possible_units = []
              this.ingredients[i].use_piece_weight = false
              if (this.ingredients[i].unit in this.UNITS_LIST) {
                this.ingredients[i].all_possible_units = Array(this.UNITS_LIST[this.ingredients[i].unit].length).fill(false)
                for (var j = 0; j < this.ingredients[i].others_units.length; j++) {
                  if (this.ingredients[i].others_units[j] < this.ingredients[i].all_possible_units.length) {
                    this.ingredients[i].all_possible_units[this.ingredients[i].others_units[j]] = true
                  }
                }
                if (this.ingredients[i].unit == 'g' && this.ingredients[i].all_possible_units[2]) {  // pièce
                  this.ingredients[i].use_piece_weigth = true
                }
                else {
                  this.ingredients[i].use_piece_weigth = false
                }
              }
              else {
                this.ingredients[i].all_possible_units = []
              }
              this.ingredients[i].chosen_allergenics = []
              this.ingredients[i].chosen_allergenics_trace_de = []
              for (const [key, value] of Object.entries(this.ingredients[i].allergenics)) {
                if (value.is_trace_de) {
                  this.ingredients[i].chosen_allergenics_trace_de.push(key)
                }
                else {
                  this.ingredients[i].chosen_allergenics.push(key)
                }
              }
            }
            this.search_status = 'ok'
            this.$forceUpdate()
          })
          .catch(error => {
            this.search_status = 'ok'
          })
      }
      else {
        this.search_status = 'ok'
        this.ingredients = null
      }
    },
    get_ingredient(id) {
      if (id == -1) {
        return this.new_ingr
      }
      for (var i = 0; i < this.ingredients.length; i++) {
        if (this.ingredients[i].id == id) {
          return this.ingredients[i]
        }
      }
      return null
    },
    add_ingredient(id) {
      event.preventDefault()
      var ingr = this.get_ingredient(id)
      var others_units = []
      for (var i = 0; i < ingr.all_possible_units.length; i++) {
        if (ingr.all_possible_units[i]) {
          others_units.push(i)
        }
      }
      var cat_id = -1
      for (i = 0; i < this.categories_list.length; i++) {
        if (get_simple_string(ingr.categorie.name) == get_simple_string(this.categories_list[i].name)) {
          cat_id = this.categories_list[i].id
        }
      }
      if (cat_id == -1) {
        this.error.is_error = true
        this.error.msg = "Catégorie invalide"
        return
      }
      api_call()
        .post('/recipes/ingredients', {
          name: ingr.name,
          unit: ingr.unit,
          others_units: others_units,
          piece_weight: ingr.piece_weight,
          categorie: cat_id,
          allergenics: ingr.chosen_allergenics,
          allergenics_trace_de: ingr.chosen_allergenics_trace_de,
        })
        .then(response => {
          console.log("Ingredient created")
          this.error.is_error = false
          this.update()
        })
        .catch(error => {
          this.error.is_error = true
          this.error.msg = error.response.data
        })
    },
    edit_ingredient(id) {
      event.preventDefault()
      var ingr = this.get_ingredient(id)
      var others_units = []
      for (var i = 0; i < ingr.all_possible_units.length; i++) {
        if (ingr.all_possible_units[i]) {
          others_units.push(i)
        }
      }
      var cat_id = -1
      for (i = 0; i < this.categories_list.length; i++) {
        if (get_simple_string(ingr.categorie.name) == get_simple_string(this.categories_list[i].name)) {
          cat_id = this.categories_list[i].id
        }
      }
      if (cat_id == -1) {
        this.error.is_error = true
        this.error.msg = "Catégorie invalide"
        return
      }
      api_call()
        .put('/recipes/ingredients/' + ingr.id, {
          name: ingr.name,
          unit: ingr.unit,
          others_units: others_units,
          piece_weight: ingr.piece_weight,
          categorie: cat_id,
          allergenics: ingr.chosen_allergenics,
          allergenics_trace_de: ingr.chosen_allergenics_trace_de,
        })
        .then(response => {
          console.log("Ingredient updated")
          this.error.is_error = false
          this.update()
        })
        .catch(error => {
          this.error.is_error = true
          this.error.msg = error.response.data
        })
    },
    delete_ingredient(id) {
      if (!window.confirm("Voulez-vous vraiment supprimer l'ingrédient ?"))
        return
      api_call()
        .delete("/recipes/ingredients/" + id)
        .then(reponse => {
          this.error.is_error = false
          this.update()
        })
        .catch(error => {
          this.error.is_error = true
          this.error.msg = error.response.data
        })
    },
  },
  watch: {
    need_update: {
      handler(val, oldval) {
        this.$forceUpdate()
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
.form-div-table {
  padding: 0px;
}
.form-div-table>div>form {
  border-style: none;
}
</style>