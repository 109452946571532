<template>
  <b-container>
    <b-row>
      <h1>Vous avez trouvé la route !!!</h1>
      <p>Sur l'ancien site web Tim & Bastien, la route était l'élément centrale du site web. Aujourd'hui remplacé par un autre design, nous avons décidé de laisser cette route caché quelque part dans les méandres du site.</p>
    </b-row>
    <div class="around-route-div"></div>
    <div class="around-route-div"></div>

    <!-- <b-row style="background-color: var(--le-projet-bg);"> -->
    <b-row>
      <route></route>
    </b-row>
    <div class="around-route-div"></div>
    <div class="around-route-div"></div>
    <div class="around-route-div"></div>
    <div class="around-route-div"></div>
    <div class="around-route-div"></div>
    <div class="around-route-div"></div>
    <div class="around-route-div"></div>
  </b-container>

</template>

<script>
import Route from '@/components/projet_old/Route.vue'

export default {
  name: "Route_page",
  components: {
    Route,
  }
}
</script>

<style scoped>
#route-page {
  background-color: var(--le-projet-bg);
}
</style>