<template>  <b-col>
    <b-form @submit="edit_destination" class="form-base-class">
      <b-form-group label="Date de début:">
        <b-form-datepicker
          v-model="new_dest.start_date"
          :value-as-date="true"
          start-weekday=1
          locale="fr-FR"
          calendar-width="100%"
          menu-class="w-100"
          required
        />
      </b-form-group>
      <b-form-group label="Date de fin:">
        <b-form-datepicker
          v-model="new_dest.end_date"
          :value-as-date="true"
          start-weekday=1
          locale="fr-FR"
          calendar-width="100%"
          menu-class="w-100"
          required
        />
      </b-form-group>
      <b-form-group label="Nom du campus:">
        <b-form-input v-model="new_dest.campus_name" required></b-form-input>
      </b-form-group>
      <b-form-group label="Adresse:">
        <b-form-input v-model="new_dest.campus_address_street" required></b-form-input>
      </b-form-group>
      <b-form-group label="Code postal:">
        <b-form-input v-model="new_dest.campus_address_postal_code" v-on:keyup="postcode_updated()" required></b-form-input>
      </b-form-group>
      <b-form-group label="Ville:">
        <b-form-input v-model="new_dest.campus_address_city" required></b-form-input>
      </b-form-group>
      <b-form-group label="Publier ?">
        <b-button :pressed.sync="new_dest.is_draft" variant="secondary">
          <fragment v-if="new_dest.is_draft">{{ "Brouillon" }}</fragment>
          <fragment v-else>{{ "Publié" }}</fragment>
        </b-button>
      </b-form-group>
      <div class="form-validate-buttons">
        <b-button type="submit" variant="success">
          <b-icon icon="pencil"/> Modifier la destination
        </b-button>
        <b-button type="button" v-on:click="delete_destination()" variant="danger">
          <b-icon icon="x-circle"/> Supprimer
        </b-button>
      </div>
      <p class="form-error-msg" v-if="error.is_error">
        {{ error.msg }}
      </p>
    </b-form>
  </b-col>
</template>

<script>
import { api_call, date_to_str, str_to_date } from '@/plugins/utils.js'

export default {
  name: 'DestinationEdit',
  data () {
    return {
      new_dest: {
        start_date: new Date(),
        end_date: new Date(),
        campus_name: "",
        campus_address_street: "",
        campus_address_postal_code: "",
        campus_address_city: "",
        is_draft: false,
      },
      error: {
        is_error: false,
        msg: null,
      },
    }
  },
  created () {
    this.reset_dest()
  },
  props: {
    dest_data: {
      type: Object,
      required: true,
    },
    updater: {
      type: Object,
      required: true,
    },
  },
  methods: {
    reset_dest () {
      this.new_dest.start_date = str_to_date(this.dest_data.start_date)
      this.new_dest.end_date = str_to_date(this.dest_data.end_date)
      this.new_dest.campus_name = this.dest_data.campus_name
      this.new_dest.campus_address_street = this.dest_data.campus_address_street
      this.new_dest.campus_address_postal_code = this.dest_data.campus_address_postal_code
      this.new_dest.campus_address_city = this.dest_data.campus_address_city
      this.new_dest.is_draft = this.dest_data.is_draft
    },
    delete_destination() {
      if (!window.confirm("Voulez-vous vraiment supprimer la destination ?"))
        return
      api_call()
        .delete("/destinations/" + this.dest_data.start_date.replaceAll("/", "_"))
        .then(response => {
          console.log("Destination deleted")
          this.error.is_error = false
          this.updater.update = !this.updater.update
        })
        .catch(error => {
          this.error.is_error = true
          this.error.msg = error.response.data
        })
    },
    edit_destination (event) {
      event.preventDefault()
      api_call()
        .put("/destinations/" + this.dest_data.start_date.replaceAll("/", "_"), {
          start_date: date_to_str(this.new_dest.start_date),
          end_date: date_to_str(this.new_dest.end_date),
          campus_name: this.new_dest.campus_name,
          campus_address_street: this.new_dest.campus_address_street,
          campus_address_postal_code: this.new_dest.campus_address_postal_code,
          campus_address_city: this.new_dest.campus_address_city,
          is_draft: this.new_dest.is_draft,
        })
        .then(response => {
          console.log("Destination modified")
          this.error.is_error = false
          this.updater.update = !this.updater.update
        })
        .catch(error => {
          console.log("Error while destination modifying")
          this.error.is_error = true
          this.error.msg = error.response.data
        })
    },
    postcode_updated() {
      if (this.new_dest.campus_address_postal_code.length == 5) {
        api_call()
          .get("/destinations/get_city/" + this.new_dest.campus_address_postal_code)
          .then(response => {
            this.new_dest.campus_address_city = response.data.city
          })
          .catch(error => {
            console.log(error.response.data)
          })
      }
    },
  },
}
</script>