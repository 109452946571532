<template>
  <b-dropdown variant=outline-secondary v-bind:text="title" class="m-md-2" @change="onChange($event)">
    <b-dropdown-item
      v-for="(dropdown, key) in dropdown_list" :key="key"
      v-on:click="dropdown_click(dropdown)"
      :active="is_dropdown_active(dropdown)"
    >
      {{ dropdown }}
    </b-dropdown-item>
    <b-dropdown-divider
      v-if="select_all_button || reset_button"
    ></b-dropdown-divider>
    <b-dropdown-item v-if="reset_button"
      v-on:click="dropdown_reset()"
    >
    Réinitialiser
    </b-dropdown-item>
    <b-dropdown-item v-if="select_all_button"
      v-on:click="dropdown_select_all()"
    >
    Tout séléctionner
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>

export default {
  name: 'DropdownSelectMultiple',
  components: {
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    update() {
      if (this.value) {
        this.value.need_update = !this.value.need_update
      }
      this.$forceUpdate()
    },
    dropdown_click: function(dropdown) {
      var idx = this.selected_values.indexOf(dropdown)
      if (idx == -1) {
        this.selected_values.push(dropdown)
      }
      else {
        this.selected_values.splice(idx, 1)
      }
      this.update()
    },
    is_dropdown_active: function(dropdown) {
      return this.selected_values.includes(dropdown)
    },
    dropdown_reset: function() {
      this.selected_values.splice(0, this.selected_values.length)
      this.update()
    },
    dropdown_select_all: function() {
      this.dropdown_reset()
      for (var i = 0; i < this.dropdown_list.length; i++) {
        this.selected_values.push(this.dropdown_list[i])
      }
      this.update()
    },
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    dropdown_list: {
      type: Array,
      required: true,
    },
    selected_values: {
      type: Array,
      required: true,
    },
    reset_button: {
      type: Boolean,
      required: false,
      default: true,
    },
    select_all_button: {
      type: Boolean,
      required: false,
      default: true,
    },
    value: {
      type: Object,
      default: null,
    }
  },
}
</script>