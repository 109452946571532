<template>
<div>
	<h1 class="tb-title">Par semaines</h1>
	<table class="tb-scrollable-table">
		<tr>
			<th>Initulé</th>
			<th>Date</th>
			<th>Catégorie</th>
			<th>Montant</th>
			<th>status</th>
			<th>Justif</th>
			<th>Utilisation</th>
			<th>Commentaire</th>
		</tr>
		<template v-for="(week, idx) in weeks">
			<compte-table-one-week :key="idx" :weekname="week"/>
		</template>
	</table>
</div>
</template>

<script>
import { to_euro, api_call } from '@/plugins/utils'
import CompteTableOneWeek from '@/components/compte/CompteTableOneWeek.vue'

export default {
	name: 'CompteTable',
  components: {
		CompteTableOneWeek,
  },
	data () {
		return {
			total_net: null,
			total_brut: null,
			weeks: null,
		}
	},
	created () {
		api_call()
			.get('/sumup/get_weeks')
			.then(response => {
				this.weeks = response.data.weeks
			})
	},
	mounted () {
		api_call()
			.get('/sumup/total')
			.then(response => {
				this.total_net = to_euro(response.data.total_net)
				this.total_brut = to_euro(response.data.total_brut)
			})
	},
}
</script>
