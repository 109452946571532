<template>
  <b-row class="partenaires-view full-width-row">
    <b-col>
      <h1 class="align-center">Nos partenaires</h1>
      <!-- <master-title color="white">Nos partenaires</master-title> -->
      <div class="partenaires-elements">
        <div v-for="(partenaire, key) in partenaires_list" :key="key">
          <img :src="partenaire.img" :alt="partenaire.name"/>
          <!-- <p>{{ partenaire.name }}</p> -->
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
// import MasterTitle from '@/components/utils/MasterTitle.vue'

export default {
  name: "Partenaires",
  // components: { MasterTitle },
  data() {
    return {
      partenaires_list: null,
    }
  },
  mounted() {
    this.partenaires_list = [
      {
        name: "France relance",
        img: require("@/../public/assets/partenaires/Logo France Relance 2.png"),
      },
      {
        name: "DRAAF",
        img: require("@/../public/assets/partenaires/Logo DRAAF 2.png"),
      },
      {
        name: "ARS",
        img: require("@/../public/assets/partenaires/Logo ARS.png"),
      },
      {
        name: "Université de Lorraine",
        img: require("@/../public/assets/partenaires/Logo UL.png"),
      },
      {
        name: "Métropole du Grand Nancy",
        img: require("@/../public/assets/partenaires/Logo Metropole grand nancy.png"),
      },
      // {
      //   name: "Génération Climat",
      //   img: require("@/../public/assets/partenaires/Logo Generation Climat.png"),
      // },
      // {
      //   name: "FDVA grand est",
      //   img: require("@/../public/assets/partenaires/Logo FDVA grand est.png"),
      // },
      // {
      //   name: "Fondation Auchan",
      //   img: require("@/../public/assets/partenaires/Logo fondation Auchan.png"),
      // },
    ]
    // this.partenaires_list = [
    //   {
    //     name: "Université de Lorraine",
    //     img: require("@/../public/assets/partenaires/Logo UL.png"),
    //   },
    //   {
    //     name: "ARS",
    //     img: require("@/../public/assets/partenaires/Logo ARS.png"),
    //   },
    //   {
    //     name: "DRAAF",
    //     img: require("@/../public/assets/partenaires/Logo DRAAF.png"),
    //   },
    //   {
    //     name: "France relance",
    //     img: require("@/../public/assets/partenaires/Logo France Relance.png"),
    //   },
    //   {
    //     name: "FDVA grand est",
    //     img: require("@/../public/assets/partenaires/Logo FDVA grand est.png"),
    //   },
    //   {
    //     name: "Ville de Nancy",
    //     img: require("@/../public/assets/partenaires/Logo Nancy.png"),
    //   },
    //   {
    //     name: "Ville de Montigny-le-Bretonneux",
    //     img: require("@/../public/assets/partenaires/Logo montigny.png"),
    //   },
    // ]
  },
}
</script>

<style scoped>
.partenaires-view {
  background-color: var(--color-green-dark);
  padding-left: 3rem;
  padding-right: 3rem;
}

.partenaires-elements {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  align-content: space-around;
}

.partenaires-elements>div {
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.partenaires-elements>div>p {
  font-weight: bold;
  text-align: center;
}
.partenaires-elements>div>img {
  height: 4rem;
}
</style>