<template>
  <b-container>
    <b-row>
      <b-col lg="1"></b-col>
      <b-col lg="10" align="center">
        <h1 class="tb-title">L'association Tim & Bastien recrute !</h1>
        <p>L'association Tim & Bastien recrute des étudiant·es qui veulent s'engager dans la transition alimentaire. </p>
        <p>Pour toutes celles et ceux qui veulent donner ponctuellement de leur temps et de leur talent, nous vous invitons à nous contacter directement sur notre adresse mail contact@timbastien.fr.</p>
        <p>De même pour postuler, nous vous invitons à prendre contact avec nous via notre adresse mail contact@timbastien.fr.</p>
        <h2 class="tb-title-2">Les fiches de poste :</h2>
        <b-button class="recrute-dl-btn" variant="outline-secondary" size="lg" href="assets/fiche_poste/Fiche de poste cuisine VF 2024.pdf" download>Fiche 1 : [CDD] Cuisinier.ère & sensibilisation au food truck</b-button>
        <b-button class="recrute-dl-btn" variant="outline-secondary" size="lg" href="assets/fiche_poste/Fiche de poste T&B - Délégué.e Général.e VF 2024.pdf" download>Fiche 2 : [CDI] Délégué.e Général.e</b-button>

        <h2 class="tb-title-2">Les fiches missions bénévoles :</h2>
        <b-button class="recrute-dl-btn" variant="outline-secondary" size="lg" href="assets/fiche_poste/Fiche mission bénévoles - Communication.pdf" download>Fiche mission bénévoles - Communication</b-button>
        <b-button class="recrute-dl-btn" variant="outline-secondary" size="lg" href="assets/fiche_poste/Fiche mission bénévoles - Evenement.pdf" download>Fiche mission bénévoles - Evenement</b-button>
      </b-col>
      <b-col lg="1"></b-col>
    </b-row>
  </b-container>
</template>

<script>
import { is_phone } from '@/plugins/utils.js'

export default {
  name: 'Nous_recrutons',
  data () {
    return {
      is_phone: is_phone,
    }
  },
  mounted () {
  },
}
</script>

<style>
.recrute-dl-btn {
  margin: 0.3rem;
}
</style>
