<template>
  <div>
    <b-spinner v-if="status == 'loading'" label="" :style="style"></b-spinner>
    <b-icon v-if="status == 'ok'" :icon="icon_ok" :style="style" style="margin-bottom: 0px !important;"></b-icon>
    <b-icon v-if="status == 'error'" :icon="icon_error" :style="style" style="margin-bottom: 0px !important;"></b-icon>
    <b-icon v-if="status == '...'" :icon="icon_three_dots" :style="style" style="margin-bottom: 0px !important;"></b-icon>
  </div>
</template>

<script>
export default {
  name: 'ApiCallStatus',
  data() {
    return {
      real_scale: null,
      style: "",
    }
  },
  created() {
    this.real_scale = this.scale * 3.5
    this.style = 'width: ' + this.real_scale + 'vh; height: ' + this.real_scale + 'vh'
  },
  props: {
    status: {
      type: String,
      required: true,
    },
    scale: {
      type: Number,
      default: 1,
    },
    icon_ok: {
      type: String,
      default: 'check2',
    },
    icon_error: {
      type: String,
      default: 'x',
    },
    icon_three_dots: {
      type: String,
      default: 'three-dots',
    },
  }
}
</script>
