<template>
  <div>
    <b-form @submit="update_steps_order()" class="form-base-class form-full-width-items" v-if="steps">
      <h3 class="tb-title-3">Ordre des étapes</h3>
      <!-- forms values -->

      <table class="recipe-edit-ingredient">
        <tr>
          <th>Étape</th>
          <th>Ordre</th>
        </tr>
        <fragment v-for="(step, idx_step) in steps" :key="idx_step">
            <tr>
              <td>{{ step.name }}</td>
              <td class="input-cell"><b-form-input type="number" v-model="step.order"></b-form-input></td>
            </tr>
        </fragment>
      </table>

      <!-- validation buttons -->
      <div class="form-validate-buttons">
        <b-button type="submit" variant="success">
          <b-icon icon="pencil"/> Modifier l'ordre
        </b-button>
        <api-call-status :status="error.fetch_status" />
      </div>
      <!-- errors -->
      <p class="form-error-msg" v-if="error.is_error">{{ error.msg }}</p>
    </b-form>
    <div v-else>
      <p class="form-error-msg" v-if="error.is_error">{{ error.msg }}</p>
    </div>
  </div>
</template>

<script>
import { api_call } from '@/plugins/utils.js'
import ApiCallStatus from "@/components/utils/ApiCallStatus.vue"

export default {
  name: 'RecipesStepsOrder',
  components: {
    ApiCallStatus,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    recipe_id: {
      type: Number,
      required: true,
    },
    value: {  // v-model
      type: Object,
      required: true,   
    },
  },
  data() {
    return {
      error: { is_error: false, msg: null, fetch_status: "" },
    }
  },
  methods: {
    update_steps_order() {
      event.preventDefault()
      var st = []
      for (const step of this.steps) {
        st.push({
          id: step.id,
          order: step.order,
        })
      }
      api_call()
        .put("/recipes/" + this.recipe_id + "/steps/reorder", {
          steps: st,
        })
        .then(reponse => {
          this.error.is_error = false
          this.error.fetch_status = "ok"
          this.value.need_update = !this.value.need_update
        })
        .catch(error => {
          this.error.is_error = true
          this.error.fetch_status = "error"
          try {
            this.error.msg = error.response.data
          } catch (error) {
            this.error.msg = "Unexpected error"
          }
          this.value.need_update = !this.value.need_update
        })
    },
  },
}
</script>

<style scoped>
td, th {
  border: 1px dotted #434343 !important;
  text-align: center;
  padding: 8px;
}
</style>