<template>
  <fragment>
    <b-row class="justify-content-md-center admin-row">
      <b-col lg=6 class="tb-rounded-border" style="padding: 15px">
        <h1 class="tb-title-2">Navigation</h1>
        <h1 class="tb-title-3" style="text-align: left;">Recettes</h1>
        <div class="navigation-buttons">
          <b-button variant=outline-secondary href="/le-menu">Le menu</b-button>
          <b-button variant=outline-secondary href="/recettes">Voir les recettes</b-button>
        </div>
        <div class="navigation-buttons">
          <b-button variant=outline-secondary href="/admin/recettes">Éditer les recettes</b-button>
          <b-button variant=outline-secondary href="/admin/menus">Éditer les menus</b-button>
          <b-button variant=outline-secondary href="/admin/liste-courses">Liste de courses</b-button>
        </div>
        <h1 class="tb-title-3" style="text-align: left;">Autres</h1>
        <div class="navigation-buttons">
          <b-button variant=outline-secondary href="/ils-parlent-de-nous">Ils parlent de nous</b-button>
          <b-button variant=outline-secondary href="/admin/media">Éditer les médias</b-button>
        </div>
        <div class="navigation-buttons">
          <b-button variant=outline-secondary href="/admin/compte">Comptes</b-button>
          <b-button variant=outline-secondary href="/admin/destinations">Destinations du food truck</b-button>
        </div>
      </b-col>
    </b-row>
  </fragment>
</template>

<script>
export default {
  name: 'AdminBase',
}
</script>

<style scoped>
.admin-row {
  margin-top: 5px;
}
.navigation-buttons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}
.navigation-buttons>* {
  margin-right: 3px;
  margin-bottom: 3px;
}
</style>