<template>
  <div>
    <div v-for="(list, list_key) in lists" :key="list_key"
      class="list-name-div"
      v-bind:class="{ 'list-name-div-selected': list.id == cur_list_id }"
      @click="open_list(list.id)"
    >
      <div class="div-center-text">{{ list.name }}</div>
      <hr />
    </div>
    <div class="list-name-div">
      <div class="list-elem">
        <div class="check-button" @click="add_list()">
          <b-icon icon="plus-circle" font-scale="1.5"></b-icon>
        </div>
        <b-input class="name-element" v-model="new_list.name" v-on:keyup.enter="add_list()"></b-input>
      </div>
      <hr />
    </div>
    <div class="list-name-div">
      <div class="list-elem" @click="edit_lists()">
        <div class="check-button">
          <b-icon icon="pencil" font-scale="1.5"></b-icon>
        </div>
        <div class="div-center-text">Éditer les listes</div>
      </div>
      <hr />
    </div>
  </div>  
</template>

<script>
import { api_call } from '@/plugins/utils.js'

export default {
  name: 'ShoppingListEdit',
  props: {
    cur_list_id: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      lists: null,
      new_list: {
        name: ""
      },
    }
  },
  mounted() {
    this.update()
  },
  methods: {
    update() {
      api_call()
        .get("/shopping_list")
        .then(response => {
          this.lists = response.data
        })
    },
    open_list(list_id) {
      if (this.cur_list_id != list_id) {
        window.location = '/admin/liste-courses/' + list_id
      }
    },
    add_list() {
      if (this.new_list.name == "") {
        return
      }
      api_call()
        .post("/shopping_list", {
          name: this.new_list.name,
        })
        .then(response => {
          this.new_list.name = ""
          this.update()
        })
    },
    edit_lists() {
        window.location = '/admin/liste-courses/edit'
    },
  },
}
</script>

<style scoped>
.list-name-div {
  background-color: var(--main-bg-color);
}

.list-name-div-selected {
  background-color: var(--secondary-bg-color);
}

.div-center-text {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
}

.list-elem {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.check-button {
  padding: 5px;
}
.name-element {
  border: none;
}
</style>