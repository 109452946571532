<template>
  <b-container>
    <b-row>
      <b-col id="shopping-list-first-row">
          <div class="return-button">
            <b-button href="/admin/liste-courses/"><b-icon icon="caret-left" /></b-button>
          </div>
          <h1 class="tb-title">Éditer les listes de courses</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <table>
          <tr>
            <th>Ouvrir</th>
            <th>Nom</th>
            <th colspan=3>Actions</th>
          </tr>
          <tr v-for="(list, list_key) in lists" :key="list_key">
            <td class="cell-button">
              <b-button :href="'/admin/liste-courses/' + list.id" variant=""><b-icon icon="files"/></b-button>
            </td>
            <td class="input-cell"><b-input v-model="list.name"></b-input></td>
            <td class="cell-button">
              <b-button v-on:click="update_shopping_list(list.id)" variant=""><b-icon icon="pencil"/></b-button>
            </td>
            <td class="cell-button">
              <b-button v-on:click="delete_shopping_list(list.id)" variant="danger"><b-icon icon="x-circle"/></b-button>
            </td>
            <td class="cell-button">
              <api-call-status :status="list.fetch_status" :scale="0.8" />
            </td>
          </tr>
          <tr v-if="new_list">
            <td></td>
            <td class="input-cell"><b-input v-model="new_list.name"></b-input></td>
            <td class="cell-button" colspan=2 style="width: 120px;">
              <b-button v-on:click="add_shopping_list()" variant="success"><b-icon icon="plus"/></b-button>
            </td>
            <td class="cell-button">
              <api-call-status :status="new_list.fetch_status" :scale="0.8" />
            </td>
          </tr>
          <tr v-if="error.is_error">
            <td colspan=7 class="form-error-msg">{{ error.msg }}</td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ApiCallStatus from "@/components/utils/ApiCallStatus.vue"
import { api_call } from '@/plugins/utils.js'

export default {
  name: 'AdminListeCoursesEdit',
  components: {
    ApiCallStatus,
  },
  data() {
    return {
      lists: null,
      new_list: null,
      error: { is_error: false, msg: null, },
    }
  },
  mounted() {
    this.update()
  },
  methods: {
    update() {
      this.new_list = {
        name: "",
        fetch_status: "",
      }
      api_call()
        .get("/shopping_list")
        .then(response => {
          this.lists = response.data
          for (const [key, obj] of Object.entries(this.lists)) {
            obj.fetch_status = ""
          }
        })
    },
    add_shopping_list() {
      this.new_list.fetch_status = 'loading'
      if (this.new_list.name == "") {
        this.error.is_error = true
        this.error.msg = "Vous devez entrer un nom de liste"
        this.new_list.fetch_status = 'error'
        return
      }
      api_call()
        .post("/shopping_list", {
          name: this.new_list.name,
        })
        .then(response => {
          this.error.is_error = false
          this.update()
          this.new_list.fetch_status = 'ok'
        })
        .catch(error => {
          this.new_list.fetch_status = 'error'
          this.error.is_error = true
          try {
            this.error.msg = error.response.data
          } catch (error) {
            this.error.msg = "Unexpected error"
          }
        })
    },
    get_shopping_list(id) {
      if (id == -1) {
        return this.new_list
      }
      for (var i = 0; i < this.lists.length; i++) {
        if (this.lists[i].id == id) {
          return this.lists[i]
        }
      }
      return null
    },
    update_shopping_list(id) {
      var list = this.get_shopping_list(id)
      list.fetch_status = "loading"
      api_call()
        .put("/shopping_list/" + id, {
          name: list.name,
        })
        .then(response => {
          list.fetch_status = "ok"
          this.update()
        })
        .catch(error => {
          list.fetch_status = "error"
          this.error.is_error = true
          try {
            this.error.msg = error.response.data
          } catch (error) {
            this.error.msg = "Unexpected error"
          }
        })
    },
    delete_shopping_list(id) {
      if (!window.confirm("Voulez-vous vraiment supprimer la liste de courses ?"))
        return
      var list = this.get_shopping_list(id)
      list.fetch_status = "loading"
      api_call()
        .delete("/shopping_list/" + id)
        .then(response => {
          list.fetch_status = "ok"
          this.update()
        })
        .catch(error => {
          list.fetch_status = "error"
          this.error.is_error = true
          try {
            this.error.msg = error.response.data
          } catch (error) {
            this.error.msg = "Unexpected error"
          }
        })
    },
  },
}
</script>

<style scoped>
#shopping-list-first-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
#shopping-list-first-row>.return-button>* {
  margin-right: 3px;
  margin-bottom: 3px;

}
#shopping-list-first-row>h1 {
  margin-left: 15px;
}
</style>