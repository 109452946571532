<template>
  <b-row class="full-width-row">
    <div class="image-column" :style="imageStyle"></div>
    <div class="bg-text" :style="textStyle">
      <master-title :color="color" :size-factor="(is_phone() ? 1 : 2)"><slot /></master-title>
    </div>
  </b-row>
</template>

<script>
import MasterTitle from '@/components/utils/MasterTitle.vue'
import { is_phone } from '@/plugins/utils.js'

export default {
  name: 'PhotoTitle',
  components: { MasterTitle },
  props: {
    color: {
      type: String,
      required: false,
      default: "white",
    },
    image: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      is_phone: is_phone,
    }
  },
  computed: {
    imageStyle() {
      return {
        backgroundImage: "url(" + this.image + ")",
      }
    },
    textStyle() {
      if (this.color == "white") {
        return {
          textShadow: "rgba(0, 0, 0, 0.8) 0px 0px 1rem",
        }
      }
      return {
        textShadow: "rgba(1, 1, 1, 0.8) 0px 0px 1rem",
      }
    },
  },
}
</script>

<style scoped>
.image-column {
  padding: 0;
  width: 100%;
  height: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;

  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  align-content: flex-end;

  filter: blur(0.2rem);
}

.bg-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  padding: 0.3rem;
  text-align: center;
}
</style>