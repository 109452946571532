<template>
  <div>
    <h1 class="tb-title-large">Le food truck solidaire</h1>
    <div class="around-route-div"></div>
    <div id="base-div">
      <div id="route-div">
        <img id="route-img" src="@/../public/assets/route/route.png" />
        <img id="food-truck" class="z-2 floating-img" src="@/../public/assets/route/food-truck.png" hidden/>
        <img id="route-start" class="z-3 floating-img" src="@/../public/assets/route/ferme.png" hidden/>
        <img id="route-end" class="z-3 floating-img" src="@/../public/assets/route/campus.png" hidden/>
        <div id="suivez-nous">
          <h1 class="tb-title">Suivez-nous sur les réseaux</h1>
          <div>
            <a href="https://www.facebook.com/timetbastien" target="_blank"><img src="@/../public/assets/icons/fb.png"/></a>
            <a href="https://www.instagram.com/timetbastien" target="_blank"><img src="@/../public/assets/icons/insta.png"/></a>
          </div>
        </div>
        <legume legume="champi" width="60%" orientation="0deg" top="3%" left="-50%" />
        <legume legume="courgette" width="60%" orientation="20deg" top="1%" left="87%" />
        <legume legume="haricot" width="60%" orientation="30deg" top="13%" left="90%" />
        <legume legume="carotte" width="60%" orientation="-30deg" top="22%" left="-60%" />
        <legume legume="brocoli" width="60%" orientation="0deg" top="35%" left="85%" />
        <legume legume="potiron" width="60%" orientation="0deg" top="50%" left="-50%" />
        <legume legume="oignon" width="60%" orientation="0deg" top="60%" left="96%" />
        <legume legume="aubergine" width="60%" orientation="0deg" top="85%" left="86%" />
        <legume legume="tomate" width="60%" orientation="0deg" top="90%" left="-40%" />
        <legume legume="mais" width="60%" orientation="0deg" top="68%" left="-40%" />
        <!-- all checkpoints will be insered here -->
      </div>
    </div>
    <div class="around-route-div"></div>
  </div>
</template>

<script>
import { api_call } from "@/plugins/utils.js"
import Legume from "@/components/utils/Legume.vue"

export default {
  name: 'Route',
  components: {
    Legume,
  },
  data() {
    return {
      route_img_data: null,
      nb_route_data: 1000,
      checkpoints: null,
      checkpoint_translate: {
        left: {
          x: -95,
          y: -50,
        },
        right: {
          x: -5,
          y: -50,
        },
      },
    }
  },
  mounted() {
    api_call()
      .get("/route/data/" + this.nb_route_data)
      .then(response => {
        api_call()
          .get("/route/checkpoints")
          .then(checkpoints => {
            this.checkpoints = checkpoints.data
            this.route_img_data = response.data
            this.handleResize()
          })
      })
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener("resize", this.handleResize);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleScroll () {
      if (this.route_img_data == null) {
        return
      }
      var route_div = document.getElementById("route-div")
      var top = route_div.offsetTop;
      var height = route_div.offsetHeight;
      var scrollTop = window.scrollY + window.innerHeight / 2;
      var difference = scrollTop - top;
      var idx = -1
      var percent = 0
      if  ((difference > 0) && (difference < height)) {
        percent = difference / height;
        idx = Math.floor(percent * this.nb_route_data)
      }
      else if (difference < 0) {
        percent = difference - height
      }
      if (idx == -1) {
        idx = 0
      }
      if (idx >= 0 && idx < this.nb_route_data) {
        var food_truck_img = document.getElementById("food-truck")
        var x = this.route_img_data[idx].x
        var y = this.route_img_data[idx].y
        var angle = this.route_img_data[idx].angle

        x = x / 100 * route_div.clientWidth - food_truck_img.clientWidth / 2
        y = y / 100 * route_div.clientHeight - food_truck_img.clientHeight / 2
        food_truck_img.style.transform = " \
          translate3d(" + x + "px, " + y + "px, 0px) \
          rotateZ(" + angle + "deg)"

        // checkpoints
        var percent_before_appear = 30
        var percent_before_ok = 2
        for (var i = 0; i < this.checkpoints.length; i++) {
          var cur_check = document.getElementById('checkpoint-' + i)
          var x_check = this.checkpoint_translate[this.checkpoints[i].side].x
          var y_check = this.checkpoint_translate[this.checkpoints[i].side].y
          if (percent * 100 + percent_before_ok > this.checkpoints[i].percent) {
            // do nothing
          }
          else if (percent * 100 < this.checkpoints[i].percent - percent_before_appear) {
            if (this.checkpoints[i].side == 'left') {
              x_check -= 100
            }
            else {
              x_check += 100
            }
          }
          else {
            var val = (this.checkpoints[i].percent - percent * 100 - percent_before_ok) / (percent_before_appear - percent_before_ok) * 100
            if (this.checkpoints[i].side == 'left') {
              val = - val
            }
            x_check += val          }
          cur_check.style.transform = "translate(" + x_check + "%, " + y_check + "%)"
        }
      }
    },
    handleResize () {
      var route_div = document.getElementById("route-div")

      // place div around div
      Array.from(document.getElementsByClassName("around-route-div")).forEach(
        function(element, index, array) {
          element.style.height = route_div.clientHeight * 0.04 + "px"
        }
      );

      
      // place start img
      var route_start_img = document.getElementById("route-start")
      route_start_img.style.width = route_div.clientWidth * 0.4 + "px"
      route_start_img.style.left = this.route_img_data[0].x + "%"
      route_start_img.style.top = this.route_img_data[0].y + "%"
      route_start_img.style.transform = "translate(-55%, -70%)"
      route_start_img.hidden = false

      // place end img
      var route_end_img = document.getElementById("route-end")
      route_end_img.style.width = route_div.clientWidth * 0.4 + "px"
      route_end_img.style.left = this.route_img_data[this.nb_route_data - 1].x + "%"
      route_end_img.style.top = this.route_img_data[this.nb_route_data - 1].y + "%"
      route_end_img.style.transform = "translate(-50%, -40%)"
      route_end_img.hidden = false

      // place food truck
      var food_truck_img = document.getElementById("food-truck")
      food_truck_img.style.width = route_div.clientWidth * 0.15 + "px"
      food_truck_img.hidden = false

      // place checkpoints
      for (var i = 0; i < this.checkpoints.length; i++) {
        var checkpoint_parent = document.getElementById("route-div")

        var old_check = document.getElementById('checkpoint-' + i)
        if (old_check) {
          old_check.parentNode.removeChild(old_check);
        }

        var img_width = route_div.clientWidth * 2

        var new_check = document.createElement('div')
        new_check.classList.add("floating-img")
        new_check.classList.add("z-1")
        new_check.id = 'checkpoint-' + i
        new_check.style.width = img_width + "px"

        var idx = Math.floor(this.checkpoints[i].percent / 100 * this.nb_route_data)
        new_check.style.left = this.route_img_data[idx].x + "%"
        new_check.style.top = this.route_img_data[idx].y + "%"
        new_check.style.transform = "translate("
          + this.checkpoint_translate[this.checkpoints[i].side].x + "%, "
          + this.checkpoint_translate[this.checkpoints[i].side].x + "%)"

        // get the max width of the text
        var bodyRect = document.body.getBoundingClientRect()
        var elemRect = route_div.getBoundingClientRect()
        var route_x = (this.route_img_data[idx].x / 100) * route_div.clientWidth
        var text_width
        if (this.checkpoints[i].side == 'left') {
          text_width = (elemRect.left - bodyRect.left) + route_x
          text_width -= img_width * 0.11
        }
        else {
          text_width = bodyRect.right - ((elemRect.left - bodyRect.left) + route_x)
          text_width -= img_width * 0.11
        }

        // add title
        var check_title = document.createElement('h1')
        check_title.innerHTML = this.checkpoints[i].title
        check_title.classList.add("tb-title")
        check_title.classList.add("checkpoint-title")
        check_title.classList.add("checkpoint-title-" + this.checkpoints[i].side)
        check_title.style.width = text_width + "px"
        check_title.style.transform = "translate(0%, -100%)";
        new_check.appendChild(check_title)
        // add image
        var check_img = document.createElement('img')
        check_img.src = "/assets/route/" + this.checkpoints[i].type + "-" + this.checkpoints[i].side + ".png"
        check_img.style.width = '100%'
        new_check.appendChild(check_img)
        // add content
        var check_content = document.createElement('p')
        check_content.innerHTML = this.checkpoints[i].content
        check_content.classList.add("tb-text-content-large")
        check_content.classList.add("checkpoint-content")
        check_content.classList.add("checkpoint-content-" + this.checkpoints[i].side)
        check_content.style.width = text_width + "px"
        new_check.appendChild(check_content)

        checkpoint_parent.appendChild(new_check);
      }
      this.handleScroll()
    },
  },
}
</script>

<style>
#base-div {
  display: flex;
  justify-content: center;
}

#route-div {
  position: relative;
  width: 50%;
}

#route-img {
  width: 100%;
}

.floating-img {
  position: absolute;
  left: 0%;
  top: 0%;
}

.route-checkpoint>img {
  width: 100%;
}
.checkpoint-title {
  position: absolute;
  top: 40%;
  text-align: left !important;
}
.checkpoint-title-left {
  right: 15%;
}
.checkpoint-title-right {
  left: 15%;
}
.checkpoint-content {
  position: absolute;
  top: 70%;
}

.checkpoint-content-left {
  right: 15%;
}
.checkpoint-content-right {
  left: 15%;
}

#suivez-nous {
  position: absolute;
  top: 80%;
  right: -30%;
  width: 100%;
}
#suivez-nous>div {
  display: flex;
  justify-content: center;
}
#suivez-nous>div>a {
  width: 20%;
  margin-left: 2%;
  margin-right: 2%;
}
#suivez-nous>div>a>img {
  width: 100%;
}


.tb-title {
  text-align: center;
  font-weight: bold;
  font-size: 3.5vw !important;
}
@media only screen and (max-width: 768px) {
  .tb-title {
    font-size: 5vw !important;
  }
}
.tb-title-large {
  text-align: center;
  font-weight: bold;
  font-size: 5vw !important;
}
@media only screen and (max-width: 768px) {
  .tb-title-large {
    font-size: 9vw !important;
  }
}
.tb-title-2 {
  text-align: center;
  font-weight: bold;
  font-size: 2.5vw !important;
}
@media only screen and (max-width: 768px) {
  .tb-title-2 {
    font-size: 4vw !important;
  }
}
.tb-title-3 {
  text-align: center;
  font-weight: bold;
  font-size: 2vw !important;
}
@media only screen and (max-width: 768px) {
  .tb-title-3 {
    font-size: 3.5vw !important;
  }
}
.tb-title-4 {
  text-align: center;
  font-weight: bold;
  font-size: 1.3vw !important;
}
@media only screen and (max-width: 768px) {
  .tb-title-4 {
    font-size: 3.5vw !important;
  }
}
.tb-text-content-large {
  font-size: 2.5vw !important;
}
@media only screen and (max-width: 768px) {
  .tb-text-content-large {
    font-size: 4vw !important;
  }
}
</style>