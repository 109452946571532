<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <b-col lg=10 align="center">
        <h1 class="tb-title">Ils parlent de nous</h1>
        <p>Nous encourageons les membres de l'association à prendre la parole autour d'eux et dans les médias pour remettre le débat de l'alimentation au centre de la table. Sujet fondamental du XXIème, nous soutenons une alimentation saine, responsable, sociale et interpellons les décideur.se.s afin d'accélérer la transition alimentaire vers un modèle plus juste, plus durable et plus inclusif.</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col id="media-group">
        <b-card 
          v-for="(media, key) in medias" :key="key"
          :title="media.name"
          :img-src="media.image_url"
          body-class="text-center media-card-class"
          class="media-class"
          img-alt="Image" img-top
        >
          <b-button
            v-if="media.link != ''"
            target="_blank"
            variant="primary" :href="media.link">Voir plus
          </b-button>
          <template #footer>
            <small class="text-muted">{{ media.date }}</small>
          </template>
        </b-card>
      </b-col>
    </b-row>
    <b-row style="height: 2rem;"/>
  </b-container>
</template>

<script>
import { api_call, get_image_url } from '@/plugins/utils.js'

export default {
  name: 'Ils_parlent_de_nous',
  components: {
  },
  data () {
    return {
      medias: null,
    }
  },
  mounted () {
    api_call()
      .get("/media")
      .then(response => {
        this.medias = response.data
        for (var media of this.medias) {
          media.image_url = get_image_url("/media", media.id)
        }
      })
  },
}
</script>

<style scoped>
#media-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.media-class {
  max-width: 12rem;
  margin: 0.3rem;
}
.media-card-class {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
</style>
