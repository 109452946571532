<template>
  <b-row class="accueil-view">
    <img src="@/../public/assets/new_logo_color.png" id="tb-main-image" alt="Tim & Bastien">

    <photos-slider />

    <p class="large-text-center">Association reconnue d'intérêt général qui vise à accélérer la transition alimentaire sur les campus étudiants et à lutter contre la précarité alimentaire des étudiant.e.s.</p>
    <p class="large-text-center">Nous soutenons une alimentation durable et accessible avec des <b>produits bio</b> en <b>circuits courts</b>.</p>
</b-row>
</template>

<script>
import PhotosSlider from '@/components/projet/PhotosSlider.vue'
export default {
  name: "Accueil",
  components: {
    PhotosSlider,
  },
}
</script>

<style scoped>
.accueil-view {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

#tb-main-image {
  align-self: center;
  width: 50%;
}
@media only screen and (max-width: 768px) {
  #tb-main-image {
    width: 80%;
  }
}
</style>