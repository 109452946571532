import { render, staticRenderFns } from "./Nos_recettes_view.vue?vue&type=template&id=732e1efe&"
import script from "./Nos_recettes_view.vue?vue&type=script&lang=js&"
export * from "./Nos_recettes_view.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports