<template>
  <div class="edit-menu">
    <div class="accordion" role="tablist">
      <table v-if="recipes_list && shopping_lists">
        <tr>
          <th style="min-width: 150px;">Date</th>
          <th style="min-width: 200px;">Plat</th>
          <th style="width: 80px;">Nb personnes</th>
          <th style="min-width: 200px;">Dessert</th>
          <th style="width: 80px;">Nb personnes</th>
          <th colspan=4>Actions</th>
        </tr>
        <fragment v-for="(menu, menu_key) in menus" :key="menu_key">
          <tr>
            <menus-edit-line :menu="menu" :recipes_list="recipes_list"/>
            <td class="cell-button">
              <b-button v-on:click="update_menu(menu.id)" variant=""><b-icon icon="pencil"/></b-button>
            </td>
            <td class="cell-button">
              <b-button v-on:click="delete_menu(menu.id)" variant="danger"><b-icon icon="x-circle"/></b-button>
            </td>
            <td class="cell-button">
              <b-button v-b-toggle="menu.id_collapse" variant=""><b-icon icon="list-check"/></b-button>
            </td>
            <td class="cell-button">
              <api-call-status :status="menu.fetch_status" :scale="0.8" />
            </td>
          </tr>
          <tr>
            <td colspan="5" class="form-div-table" style="border: none !important;"></td>
            <td colspan="4" class="form-div-table">
              <b-collapse :id="menu.id_collapse" accordion="menu-accordion">
                <div class="select-list">
                  <b-button variant=outline-success class="menu-select-btn" disabled>Plat</b-button>
                  <b-button variant=outline-warning class="menu-select-btn" disabled>Dessert</b-button>
                </div>
                <div v-for="(list, list_idx) in shopping_lists" :key="list_idx" class="select-list">
                  <b-button v-on:click="add_plat_to_list(menu, list)" variant=outline-success class="menu-select-btn">{{ list.name }}</b-button>
                  <b-button v-on:click="add_dessert_to_list(menu, list)" variant=outline-warning class="menu-select-btn">{{ list.name }}</b-button>
                </div>
              </b-collapse>
            </td>
          </tr>
        </fragment>
        <tr v-if="new_menu">
          <menus-edit-line :menu="new_menu" :recipes_list="recipes_list"/>
          <td class="cell-button" colspan=3>
            <b-button v-on:click="add_menu()" variant="success"><b-icon icon="plus"/></b-button>
          </td>
          <td class="cell-button">
            <api-call-status :status="new_menu.fetch_status" :scale="0.8" />
          </td>
        </tr>
        <tr v-if="error.is_error">
          <td colspan=7 class="form-error-msg">{{ error.msg }}</td>
        </tr>
      </table>
      <div style="width: 100%; height: 400px;"></div>
    </div>
  </div>
</template>

<script>
import ApiCallStatus from "@/components/utils/ApiCallStatus.vue"
import { api_call, str_to_date, date_to_str } from '@/plugins/utils.js'
import MenusEditLine from '@/components/menu/MenusEditLine.vue'

export default {
  name: 'MenusEdit',
  components: {
    ApiCallStatus,
    MenusEditLine,
  },
  data () {
    return {
      recipes_list: null,
      shopping_lists: null,
      menus: null,
      new_menu: null,
      error: { is_error: false, msg: null, },
    }
  },
  mounted () {
    api_call()
      .get("/recipes")
      .then(response => {
        this.recipes_list = response.data
      })
    api_call()
      .get("/shopping_list")
      .then(response => {
        this.shopping_lists = response.data
      })
    this.update()
  },
  methods: {
    update() {
      this.new_menu = {
        date: new Date(),
        recipe: { name: "" },
        nb_people: 0,
        dessert: { name: "" },
        dessert_nb_people: 0,
        comment: "",
        fetch_status: "",
      }
      api_call()
        .get("/menus?discard_old=true")
        .then(response => {
          this.menus = response.data
          for (const [key, obj] of Object.entries(this.menus)) {
            obj.date = str_to_date(obj.date)
            obj.fetch_status = ""
            obj.id_collapse = "collapse-menu-shopping-lists-" + obj.id
          }
        })
    },
    add_menu() {
      this.new_menu.fetch_status = 'loading'
      if (this.new_menu.recipe.name == "") {
        this.error.is_error = true
        this.error.msg = "Vous devez entrer un nom de recette"
        this.new_menu.fetch_status = 'error'
        return
      }
      if (this.new_menu.nb_people < 0) {
        this.error.is_error = true
        this.error.msg = "Vous devez entrer un nombre de personnes valide"
        this.new_menu.fetch_status = 'error'
        return
      }
      if (this.new_menu.dessert.name == "") {
        this.error.is_error = true
        this.error.msg = "Vous devez entrer un nom de dessert"
        this.new_menu.fetch_status = 'error'
        return
      }
      if (this.new_menu.dessert_nb_people < 0) {
        this.error.is_error = true
        this.error.msg = "Vous devez entrer un nombre de personnes valide"
        this.new_menu.fetch_status = 'error'
        return
      }
      api_call()
        .post("/menus", {
          date: date_to_str(this.new_menu.date),
          recipe_name: this.new_menu.recipe.name,
          nb_people: this.new_menu.nb_people,
          dessert_name: this.new_menu.dessert.name,
          dessert_nb_people: this.new_menu.dessert_nb_people,
          comment: this.new_menu.comment,
        })
        .then(response => {
          this.error.is_error = false
          this.update()
          this.new_menu.fetch_status = 'ok'
        })
        .catch(error => {
          this.new_menu.fetch_status = 'error'
          this.error.is_error = true
          try {
            this.error.msg = error.response.data
          } catch (error) {
            this.error.msg = "Unexpected error"
          }
        })
    },
    get_menu(id) {
      if (id == -1) {
        return this.new_menu
      }
      for (var i = 0; i < this.menus.length; i++) {
        if (this.menus[i].id == id) {
          return this.menus[i]
        }
      }
      return null
    },
    update_menu(id) {
      var menu = this.get_menu(id)
      menu.fetch_status = "loading"
      api_call()
        .put("/menus/" + id, {
          date: date_to_str(menu.date),
          recipe_name: menu.recipe.name,
          nb_people: menu.nb_people,
          dessert_name: menu.dessert.name,
          dessert_nb_people: menu.dessert_nb_people,
          comment: menu.comment,
        })
        .then(response => {
          menu.fetch_status = "ok"
          this.update()
        })
        .catch(error => {
          menu.fetch_status = "error"
          this.error.is_error = true
          try {
            this.error.msg = error.response.data
          } catch (error) {
            this.error.msg = "Unexpected error"
          }
        })
    },
    delete_menu(id) {
      if (!window.confirm("Voulez-vous vraiment supprimer le menu ?"))
        return
      var menu = this.get_menu(id)
      menu.fetch_status = "loading"
      api_call()
        .delete("/menus/" + id)
        .then(response => {
          menu.fetch_status = "ok"
          this.update()
        })
        .catch(error => {
          menu.fetch_status = "error"
          this.error.is_error = true
          try {
            this.error.msg = error.response.data
          } catch (error) {
            this.error.msg = "Unexpected error"
          }
        })
    },
    add_plat_to_list(menu, list) {
      api_call()
        .post("/shopping_list/" + list.id + "/menu/" + menu.id + "?plat=true")
        .then(response => {
          var collapse_elem = document.getElementById(menu.id_collapse)
          collapse_elem.style = "display: none;"
        })
        .catch(error => {
        })
    },
    add_dessert_to_list(menu, list) {
      api_call()
        .post("/shopping_list/" + list.id + "/menu/" + menu.id + "?dessert=true")
        .then(response => {
          var collapse_elem = document.getElementById(menu.id_collapse)
          collapse_elem.style = "display: none;"
        })
        .catch(error => {
        })
    },
    add_menu_to_list(menu, list) {
      api_call()
        .post("/shopping_list/" + list.id + "/menu/" + menu.id + "?plat=true&dessert=true")
        .then(response => {
          var collapse_elem = document.getElementById(menu.id_collapse)
          collapse_elem.style = "display: none;"
        })
        .catch(error => {
        })
    },
  },
}
</script>

<style scoped>
.edit-menu {
  overflow-x: auto !important;
}
.form-div-table {
  padding: 0px;
}
.select-list {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}
.menu-select-btn {
  margin: 3px;
  width: 100%;
}
</style>