<template>
  <div class="tb-rounded-border" style="padding: 15px; margin-bottom: 1rem;">
    <h4 class="tb-title-4">Adhérer à l'association</h4>
    <b-form @submit="onSubmit">
      <b-form-group label="Nom :">
        <b-form-input
          v-model="form.lastname"
          placeholder=""
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Prénom :">
        <b-form-input
          v-model="form.firstname"
          placeholder=""
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Adresse mail :">
        <b-form-input
          v-model="form.email"
          type="email"
          placeholder=""
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group>
        <label>Date de naissance</label>
        <b-form-datepicker v-model="form.birth_date" class="mb-2" show-decade-nav></b-form-datepicker>
      </b-form-group>

      <b-form-group>
        <b-form-input
          v-model="form.profil"
          placeholder="Étudiant, Chercheur, Salarié, etc."
        ></b-form-input>
      </b-form-group>

      <label>Mot de passe :</label>
      <b-form-input type="password" aria-describedby="password-help-block" v-model="form.password_1"></b-form-input>

      <label>Retappez le mot de passe :</label>
      <b-form-input type="password" aria-describedby="password-help-block" v-model="form.password_2"></b-form-input>

      <b-checkbox v-model="form.cgu" name="cgu" value="true" required>
        J'accepte les <a href="/cgu" target="_blank">conditions générales d'utilisation</a>
      </b-checkbox>

      <div class="connect-btn">
        <b-button type="submit" variant="outline-primary" class="mt-2">Adhérer à l'association</b-button>
      </div>
      <p class="invalid-password" v-if="error_login.is_error">
        {{ error_login.msg }}
      </p>
    </b-form>
    <p style="margin-top: 1rem; margin-bottom: 0px; text-align: center;">
      Une question ? <a href="mailto:contact@timbastien.fr">contact@timbastien.fr</a>
    </p>
  </div>
</template>

<script>
import { api_call, date_to_str } from '@/plugins/utils.js'

export default {
  name: 'CreateAccount',
  components: {
  },
  data() {
    return {
      form: {
        email: '',
        firstname: '',
        lastname: '',
        password_1: '',
        password_2: '',
        birth_date: null,
        profil: '',
        cgu: false,
      },
      error_login: {
        is_error: false,
        msg: null,
      },
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      if (!this.form.cgu) {
        this.error_login.is_error = true
        this.error_login.msg = "Vous devez accepter les conditions générales d'utilisation"
        return;
      }
      if (this.form.password_1 != this.form.password_2) {
        this.error_login.is_error = true
        this.error_login.msg = "Le mot de passe doit être le même"
        return;
      }
      api_call()
        .post("/login/new", {
          username: this.form.email,
          firstname: this.form.firstname,
          lastname: this.form.lastname,
          birth_date: this.form.birth_date,
          profil: this.form.profil,
          password: this.form.password_1,
        })
        .then(response => {
          this.error_login.is_error = false
          api_call()
            .post("/login", {
              username: this.form.email,
              password: this.form.password_1,
            })
            .then(response => {
              this.error_login.is_error = false
              this.$store.commit('LOGIN_SUCCESS', response)
              window.location = "/adhesion"
            })
            .catch(error => {
              this.error_login.is_error = true
              try {
                this.error_login.msg = error.response.data
              } catch (error) {
                this.error_login.msg = "Unexpected error"
              }
            })
        })
        .catch(error => {
          this.error_login.is_error = true
          try {
            this.error_login.msg = error.response.data
          } catch (error) {
            this.error_login.msg = "Unexpected error"
          }
        })
    },
  },
}
</script>

<style>
.invalid-password {
  color: red;
}
.connect-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
