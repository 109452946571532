<template>
  <b-container fluid="sm" id="asso-page">
    <photo-title
      :image="require('@/../public/assets/photos/new_food_truck.jpg')"
    >L'association</photo-title>

    <b-row class="justify-content-md-center">
      <b-col lg=12 align="center">
        <master-title>Historique</master-title>
        <p class="large-text">Tim & Bastien est une association loi 1901 créée en février 2021.</p>
        <p class="large-text">A l'origine du projet, Tim & Bastien, deux amis d'enfance, étudiants à Nancy, en césure  juste après la période covid. Ils font alors le constat que la précarité alimentaire étudiante est en forte hausse. Leur idée ? Lancer un food truck solidaire sur les campus de l'Université de Lorraine.</p>
      </b-col>
    </b-row>

    <objectifs />

    <b-row class="justify-content-md-center">
      <b-col lg=12 align="center">
        <p class="large-text">Pour cela l'association agit selon <b>3 axes de travail : Restaurer, Sensibiliser, Accompagner</b>.</p>
        <p class="large-text">L'association porte des projets solidaires, accessibles à tou.te.s et inclusifs. Elle se veut également un territoire d'expérimentation pour de nouveaux projets et modèles alimentaires. Par son food truck itinérant, elle promeut la proximité avec les étudiant.e.s.</p>
        <p class="large-text">Nous défendons un modèle alimentaire plus juste, plus local, plus respectueux de l'environnement, accessible à tou.te.s et qui rémunère mieux les producteurs.rices. Notre cuisine est végétarienne, cuisinée à partir de produits au maximum de saison, locaux et biologiques. </p>
        <p class="large-text">Par nos différentes actions, nous accompagnons les étudiant.e.s vers une transition alimentaire durable et réalisable à l'échelle de chacun.e.</p>
        <p class="large-text">Enfin, nous portons la voix des étudiant.e.s et apportons notre expertise auprès des instances décisionnelles pour participer activement à la transition alimentaire, écologique et solidaire des campus étudiants.</p>
        <p class="large-text">Notre food truck permet de créer un lieu alternatif et éphémère sur les campus, de partage et d'échange, pour parler des transitions alimentaires, écologiques et solidaires avec les étudiant.e.s.</p>
      </b-col>
    </b-row>

    <legumes-line />
    <faire-un-don />

    <master-title>Témoignage d'Anaïs</master-title>
    <VideoPlayer video="https://drive.google.com/file/d/1kwF8dMyVWU3fVUwsSaRLTuuVk6YO60hO/preview" />

    <documents />
    <partenaires />
    <tb-footer />
  </b-container>
</template>

<script>
import MasterTitle from '@/components/utils/MasterTitle.vue'
import PhotoTitle from '@/components/utils/PhotoTitle.vue'
import VideoPlayer from '@/components/utils/VideoPlayer.vue'
import LegumesLine from '@/components/utils/LegumesLine.vue'
import Objectifs from '@/components/projet/Objectifs.vue'
import FaireUnDon from '@/components/association/FaireUnDon.vue'
import Documents from '@/components/association/Documents.vue'
import Partenaires from '@/components/projet/Partenaires.vue'
import TbFooter from '@/components/utils/TbFooter.vue'

export default {
  name: "Association",
  components: {
    MasterTitle,
    PhotoTitle,
    VideoPlayer,
    LegumesLine,
    Objectifs,
    FaireUnDon,
    Documents,
    Partenaires,
    TbFooter,
  }
}
</script>

<style scoped>
#asso-page {
  display: flex;
  flex-direction: column;
  justify-content: top;
}
</style>