<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <b-col lg=10 align="center">
        <h1 class="tb-title">Devenir adhérent</h1>
          <p>Pour réussir le pari ambitieux d'accompagner 10 000 étudiant.e.s dans leur transition alimentaire d'ici la fin de l'année, l'association Tim & Bastien est à la recherche de personnes motivées pour atteindre ce pari un peu fou.</p>

          <p>Nos missions sont variées et de nouvelles idées émanent chaque jour des membres de l'association :</p>
          <dl>
            <dt>Cuisiner dans le food truck</dt>
            <dt>Trouver de nouvelles recettes</dt>
            <dt>Concevoir de nouvelles actions de sensibilisation</dt>
            <dt>Repenser le design du food truck</dt>
            <dt>Créer des oeuvres artistiques </dt>
            <dt>Faire des partenariats avec des monnaies locales</dt>
            <dt>etc.</dt>
          </dl>

          <p>Chez Tim & Bastien, chacun peut s'engager où il se sentira le plus utile. Alors si toi aussi tu as envie de t'engager, n'attends plus et <a href="/login">inscris-toi</a> !</p>

        <h2 class="tb-title-2">Les fiches missions :</h2>
        <b-button class="recrute-dl-btn" variant="outline-secondary" size="lg" href="assets/fiche_poste/Fiche mission bénévoles - Communication.pdf" download>Fiche mission bénévoles - Communication</b-button>
        <b-button class="recrute-dl-btn" variant="outline-secondary" size="lg" href="assets/fiche_poste/Fiche mission bénévoles - Evenement.pdf" download>Fiche mission bénévoles - Evenement</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'Devenir_adherent',
  data () {
    return {
    }
  },
  mounted () {
  },
}
</script>

<style>
</style>
