<template>
  <div class="tb-rounded-border" style="padding: 15px">
    <h4 class="tb-title-4">Connexion</h4>
    <b-form @submit="onSubmit">
      <b-form-group label="Adresse mail :">
        <b-form-input
          id="input-1"
          v-model="form.email"
          type="email"
          placeholder=""
          required
        ></b-form-input>
      </b-form-group>

      <label>Mot de passe :</label>
      <b-form-input type="password" aria-describedby="password-help-block" v-model="form.password"></b-form-input>

      <div class="connect-btn">
        <b-button type="submit" variant="outline-primary" class="mt-2">Connexion</b-button>
      </div>
      <p class="invalid-password" v-if="error_login.is_error">
        {{ error_login.msg }}
      </p>

    </b-form>
  </div>
</template>

<script>
import { api_call } from '@/plugins/utils.js'

export default {
  name: 'Connect',
  components: {
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      error_login: {
        is_error: false,
        msg: null,
      },
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      api_call()
        .post("/login", {
          username: this.form.email,
          password: this.form.password,
        })
        .then(response => {
          this.error_login.is_error = false
          this.$store.commit('LOGIN_SUCCESS', response)
          window.history.back()
        })
        .catch(error => {
          this.error_login.is_error = true
          try {
            this.error_login.msg = error.response.data
          } catch (error) {
            this.error_login.msg = "Unexpected error"
          }
        })
    },
  },
}
</script>

<style>
.invalid-password {
  color: red;
}
.connect-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
