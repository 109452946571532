<template>
  <b-row class="full-width-row">
    <b-col class="video-col">
      <iframe :src="video" :style="videoPlayer" allow="autoplay"></iframe>
    </b-col>
  </b-row>
</template>

<script>
import { is_phone } from '@/plugins/utils.js'

export default {
  name: 'VideoPlayer',
  props: {
    video: {
      type: String,
      required: true
    },
    width: {
      type: String,
      required: false,
      default: "",
    },
  },
  data () {
    return {
      is_phone: is_phone,
    }
  },
  computed: {
    videoPlayer() {
      var w = this.width;
      if (w == "") {
        if (this.is_phone()) {
          w = "100%";
        } else {
          w = "70%";
        }
      }
      return {
        minWidth: w,
        aspectRatio: "16 / 9",
        margin: "0 auto",
      }
    },
  },
}
</script>

<style scoped>
.video-col {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 2rem;
}
</style>
