<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <b-col lg=10 align="center">
        <h1 class="tb-title">Accompagnement de projet</h1>
        <p>Fort de son expérience dans le développement rapide de projets étudiants en lien avec la lutte contre la précarité alimentaire et la transition alimentaire, l'association Tim & Bastien propose un accompagnement gratuit à toutes celles et tous ceux qui le souhaitent.</p>
        <p>Vous pouvez nous contacter en nous adressant directement un mail à l'adresse suivante : <a href="mailto:contact@timbastien.fr">contact@timbastien.fr</a></p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { is_phone } from '@/plugins/utils.js'

export default {
  name: 'Accompagnement_projet',
  data () {
    return {
      is_phone: is_phone,
    }
  },
  mounted () {
  },
}
</script>

<style>
</style>
