<template>
  <div class="legume-img" :style="imgDivStyle">
    <img :src="legume_img" :style="imgStyle"/>
  </div>
</template>

<script>
// example:
// <legume legume="courgette" size="200px" orientation="40deg" top="10%" left="10%" />

// V1
  // aubergine
  // avocat
  // brocoli
  // carotte
  // champi
  // citron
  // courgette
  // haricot
  // mais
  // oignon
  // pasteque
  // patate
  // poireau
  // pomme
  // potiron
  // salade
  // tomate

// V2
  // abricot
  // aubergine
  // brocoli
  // carotte
  // citrouille
  // cornichon
  // noix
  // oignon
  // poivron
  // pomme
  // tomate

export default {
  name: 'Legume',
  data() {
    return {
      legume_img: null,
    }
  },
  mounted() {
    if (this.v2) {
      this.legume_img = "/assets/legumes_v2/" + this.legume + ".png"
    }
    else {
      this.legume_img = "/assets/legumes/" + this.legume + ".png"
    }
  },
  props: {
    legume: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: false,
    },
    height: {
      type: String,
      required: false,
    },
    orientation: {
      type: String,
      required: false,
      default: "0deg"
    },
    top: {
      type: String,
      required: false,
    },
    bottom: {
      type: String,
      required: false,
    },
    left: {
      type: String,
      required: false,
    },
    right: {
      type: String,
      required: false,
    },
    v2: {
      type: Boolean,
    },
  },
  computed: {
    imgDivStyle() {
      var result =  {
        'transform': 'rotateZ(' + this.orientation + ')',
        'aspect-ratio': '1'
      }
      if (this.width) {
        result['width'] = this.width
      }
      else if (this.height) {
        result['height'] = this.height
      }
      else {
        result['width'] = '100%'
      }
      if (this.top) {
        result['top'] = this.top
      }
      else if (this.bottom) {
        result['bottom'] = this.bottom
      }
      else {
        result['top'] = '0%'
      }
      if (this.left) {
        result['left'] = this.left
      }
      else if (this.right) {
        result['right'] = this.right
      }
      else {
        result['left'] = '0%'
      }
      return result
    },
    imgStyle() {
      var result = {}
      if (this.width) {
        result['width'] = "100%"
      }
      else if (this.height) {
        result['height'] = "100%"
      }
      return result
    },
  }
}
</script>

<style>
.legume-img {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.legumes-line {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.legumes-line .legume-img {
  position: static;
}
</style>