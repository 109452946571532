<template>
  <b-container id="all-destinations">
    <b-row>
      <destination-horraire />
    </b-row>
    <b-row v-for="(val, key) in destinations" :key="key">
      <destination-edit :dest_data="val" :updater="dest_need_update"/>
    </b-row>
    <b-row>
      <destinations-add :updater="dest_need_update"/>
    </b-row>
  </b-container>
</template>

<script>
import { api_call } from '@/plugins/utils.js'
import DestinationsAdd from '@/components/destinations/DestinationsAdd.vue'
import DestinationEdit from '@/components/destinations/DestinationEdit.vue'
import DestinationHorraire from '@/components/destinations/DestinationHorraire.vue'

export default {
  name: 'AdminDestination',
  components: {
    DestinationsAdd,
    DestinationEdit,
    DestinationHorraire,
  },
  data () {
    return {
      destinations: null,
      dest_need_update: {
        update: false
      },
    }
  },
  mounted () {
    this.update_destinations()
  },
  methods: {
    update_destinations() {
      api_call()
        .get("/destinations")
        .then(response => {
          this.destinations = response.data
        })
    },
  },
  watch: {
    dest_need_update: {
      handler(val, oldval) {
        this.update_destinations()
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
#all-destinations>* {
  margin-bottom: 10px;
}
</style>