import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    paths: [
      'bearer_token',
      'username',
      'is_connected',
      'is_admin',

      'recipes_nb_people',

      'ingr_categories',
      'shopping_list_current',
    ],
  })],
  state: {
    api_url: process.env.VUE_APP_API_URL,
		float_separator: ',',  // should be , or .
    bearer_token: null,
    username: "",
    is_connected: false,
    is_admin: false,
    connect_interval: 1000,  // every X milliseconds, check connection
    update_sumup_interval: 3000,  // every X milliseconds, check connection
    navbar_height_px: 0,
    socket: {
      socket: null,
      is_connected: false,
    },

    recipes_nb_people: {
      recipe_id: -1,
      nb_people: null,
    },

    ingr_categories: [],
    shopping_list_current: {
      list_id: null,
      list: null,
      hide_checked: false,
      hide_adding: true,
    },
  },
  mutations: {
    LOGIN_SUCCESS(state, response) {
      state.bearer_token = response.data.access_token
      state.username = response.data.username
      state.is_connected = true
      state.is_admin = response.data.is_admin
    },
    LOGOUT_SUCCESS(state) {
      state.bearer_token = null
      state.username = ""
      state.is_connected = false
      state.is_admin = false
    },
    recipe_nb_people_set_id(state, id) {
      state.recipes_nb_people.recipe_id = id
    },
    recipe_nb_people_set_nb(state, nb) {
      state.recipes_nb_people.nb_people = nb
    },
    shopping_list_set_id(state, id) {
      state.shopping_list_current.list_id = id
    },
    shopping_list_set_list(state, list) {
      state.shopping_list_current.list = list
    },
    shopping_list_hide_checked(state, value) {
      state.shopping_list_current.hide_checked = value
    },
    shopping_list_hide_adding(state, value) {
      state.shopping_list_current.hide_adding = value
    },
  },
  actions: {
  },
  modules: {
  }
})
