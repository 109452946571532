<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <div class="mt-4" style="margin: 0.8rem">
        <h1 id="-conditions-g-n-rales-d-utilisation-">
          MENTIONS LÉGALES
        </h1>
        <p><em>Mis à jour le : 17/08/2023</em></p>
        <p>
          Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l'économie numérique, il est précisé aux utilisateurs du site Tim & Bastien l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi.
        </p>
        <h2>Edition du site</h2>
        <p>Le présent site, accessible à l’URL timbastien.fr (le « Site »), est édité par :</p>
        <p>L’association Tim & Bastien, enregistrée auprès de la préfecture/sous-préfecture de 54 - Préfecture Nancy sous le numéro W784010154, ayant son siège situé à 23 BOULEVARD ALBERT 1ER 54001 NANCY CEDEX, représentée par Bastien Maliar dûment habilité(e)</p>
        <h2>Hébergement</h2>
        <p>Le Site est hébergé par la société OVH SAS, situé 2 rue Kellermann - BP 80157 - 59053 Roubaix Cedex 1, (contact téléphonique ou email : 1007).</p>
        <h2>Directeur de publication</h2>
        <p>Le Directeur de la publication du Site est Timothée Nicolas.</p>
        <h2>Nous contacter</h2>
        <p>Par téléphone : 07 44 40 41 84</p>
        <p>Par email : contact@timbastien.fr</p>
        <p>Par courrier : 23 BOULEVARD ALBERT 1ER 54001 NANCY CEDEX</p>
      </div>
    </b-row>
  </b-container>
</template>

<script>

export default {
  name: 'MentionsLegales',
  data () {},
  created () {},
  destroyed () {},
}
</script>

<style>
p {
  text-align: justify;
}
</style>