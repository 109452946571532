<template>
	<fragment>
		<tb-nav-bar />
		<router-view/>
	</fragment>
</template>

<script>
import TbNavBar from './components/TbNavBar.vue'

export default {
  components: {
		TbNavBar,
	},
}
</script>
