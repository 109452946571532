<template>
  <recipes/>
</template>

<script>
import Recipes from '@/components/recipes/Recipes.vue'

export default {
  name: 'Nos_recettes',
  components: {
    Recipes,
  },
  data () {
    return {
    }
  },
  mounted () {
  },
}
</script>

<style>
</style>
