<template>
  <div>
    <b-form @submit="update_step()" class="form-base-class form-full-width-items" v-if="step">
      <h3 class="tb-title-3">{{ step.name }}</h3>
      <!-- forms values -->
      <b-form-group label="Nom de l'étape :">
        <b-form-input v-model="step.name" required></b-form-input>
      </b-form-group>

      <table class="recipe-edit-ingredient">
        <tr>
          <th>Ingrédient</th>
          <th>Quantité</th>
          <th colspan="3">Actions</th>
        </tr>
        <fragment v-for="(recipe_ingr, idx_ingr) in step.ingredients" :key="idx_ingr">
            <tr>
              <td>{{ recipe_ingr.name }} (en {{ recipe_ingr.unit }})</td>
              <td class="input-cell"><b-form-input v-model="recipe_ingr.qty"></b-form-input></td>
              <td class="cell-button">
                <b-button v-on:click="update_ingredient(recipe_ingr.id)" variant=""><b-icon icon="pencil"/></b-button>
              </td>
              <td class="cell-button">
                <b-button v-on:click="delete_ingredient(recipe_ingr.id)" variant="danger"><b-icon icon="x-circle"/></b-button>
              </td>
              <td class="cell-button">
                <api-call-status :status="recipe_ingr.fetch_status" :scale="0.8" />
              </td>
            </tr>
            <tr v-if="recipe_ingr.other_units != ''">
              <td colspan=5 class="step-ingr-other-units-info">{{ recipe_ingr.other_units }}</td>
            </tr>
        </fragment>
        <tr>
          <td class="input-cell">
            <div>
              <b-form-input autocomplete="off" list="ingredient-list" class="text-input-no-border" v-model="new_ingr.name"></b-form-input>

              <datalist id="ingredient-list">
                <option v-for="(ingr, key) in ingr_list" :key="key">{{ ingr.name }}</option>
              </datalist>
            </div>
          </td>
          <td class="input-cell"><b-form-input type="number" v-model="new_ingr.qty"></b-form-input></td>
          <td colspan="2" class="cell-button" style="width:100px;">
            <b-button v-on:click="add_ingredient()" variant="success"><b-icon icon="plus"/></b-button>
          </td>
          <td class="cell-button">
            <api-call-status :status="new_ingr.fetch_status" :scale="0.8" />
          </td>
        </tr>
      </table>
      <p class="form-error-msg" v-if="ingr_error.is_error">{{ ingr_error.msg }}</p>

      <b-form-group label="Contenu de l'étape :">
        <b-form-textarea v-model="step.content" rows="3" max-rows="8"></b-form-textarea>
      </b-form-group>
      <!-- validation buttons -->
      <div class="form-validate-buttons">
        <b-button type="submit" variant="success">
          <b-icon icon="pencil"/> Valider
        </b-button>
        <b-button type="button" v-on:click="delete_step()" variant="danger">
          <b-icon icon="x-circle"/> Supprimer l'étape
        </b-button>
        <api-call-status :status="error.fetch_status" />
      </div>
      <!-- errors -->
      <p class="form-error-msg" v-if="error.is_error">{{ error.msg }}</p>
    </b-form>
    <div v-else>
      <p class="form-error-msg" v-if="error.is_error">{{ error.msg }}</p>
    </div>
  </div>
</template>

<script>
import { api_call, get_simple_string } from '@/plugins/utils'
import ApiCallStatus from "@/components/utils/ApiCallStatus.vue"

export default {
  name: 'RecipeEditStep',
  components: {
    ApiCallStatus,
  },
  props: {
    recipe_id: {
      type: Number,
      required: true,
    },
    step_id: {
      type: Number,
      required: true,
    },
    value: {  // v-model
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      step: null,
      new_ingr: null,
      ingr_list: null,
      ingr_error: { is_error: false, msg: null },
      error: { is_error: false, msg: null, fetch_status: "" },
    }
  },
  mounted() {
    this.reset()
    this.update()
  },
  methods: {
    reset_ingr() {
      this.new_ingr = {
        name: '',
        qty: 0,
        fetch_status: 'ok',
      }
    },
    reset() {
      this.reset_ingr()
    },
    update() {
      api_call()
        .get('/recipes/ingredients')
        .then(response => {
          this.ingr_list = response.data
          this.error.is_error = false
        })
        .catch(error => {
          this.error.is_error = true
          try {
            this.error.msg = error.response.data
          } catch (error) {
            this.error.msg = "Unexpected error"
          }
        })
      api_call()
        .get('/recipes/' + this.recipe_id + "/steps/" + this.step_id)
        .then(response => {
          this.step = response.data
          for (var i = 0; i < this.step.ingredients.length; i++) {
            this.step.ingredients[i].fetch_status = "ok"
          }
          this.error.is_error = false
          this.$forceUpdate()
        })
        .catch(error => {
          this.error.is_error = true
          try {
            this.error.msg = error.response.data
          } catch (error) {
            this.error.msg = "Unexpected error"
          }
        })
    },
    update_step() {
      event.preventDefault()
      this.error.fetch_status = "loading"
      api_call()
        .put('/recipes/' + this.recipe_id + "/steps/" + this.step_id, {
          name: this.step.name,
          content: this.step.content,
          order: this.step.order,
        })
        .then(reponse => {
          this.error.is_error = false
          this.error.fetch_status = "ok"
          // this.value.need_update = !this.value.need_update
        })
        .catch(error => {
          this.error.is_error = true
          this.error.fetch_status = "error"
          try {
            this.error.msg = error.response.data
          } catch (error) {
            this.error.msg = "Unexpected error"
          }
        })
    },
    delete_step() {
      if (!window.confirm("Voulez-vous vraiment supprimer l'étape de la recette ?"))
        return
      this.error.fetch_status = "loading"
      api_call()
        .delete('/recipes/' + this.recipe_id + "/steps/" + this.step_id)
        .then(reponse => {
          this.error.is_error = false
          this.error.fetch_status = "ok"
          this.value.need_update = !this.value.need_update
        })
        .catch(error => {
          this.error.is_error = true
          this.error.fetch_status = "error"
          try {
            this.error.msg = error.response.data
          } catch (error) {
            this.error.msg = "Unexpected error"
          }
        })
    },
    get_ingr(id) {
      if (id == -1) {
        return this.new_ingr
      }
      for (var i = 0; i < this.step.ingredients.length; i++) {
        if (this.step.ingredients[i].id == id) {
          return this.step.ingredients[i]
        }
      }
      return null
    },
    add_ingredient() {
      var ingr = this.get_ingr(-1)
      ingr.fetch_status = "loading"
      if (ingr.qty == "") {
        ingr.qty = 0
        // this.ingr_error.is_error = true
        // ingr.fetch_status = "error"
        // this.ingr_error.msg = "Vous devez mettre une quantité"
        // return
      }
      var ingr_id = -1
      var ingr_name = get_simple_string(ingr.name) 
      for (var i = 0; i < this.ingr_list.length; i++) {
        if (ingr_name == get_simple_string(this.ingr_list[i].name)) {
          ingr_id = this.ingr_list[i].id
          break
        }
      }
      if (ingr_id != -1) {
        api_call()
          .post("/recipes/" + this.recipe_id + "/steps/" + this.step_id + "/ingredients/", {
            ingr_id: ingr_id,
            qty: ingr.qty,
          })
          .then(response => {
            this.update()
            this.ingr_error.is_error = false
            ingr.fetch_status = "ok"
            this.reset_ingr()
          })
          .catch(error => {
            this.ingr_error.is_error = true
            ingr.fetch_status = "error"
            try {
              this.ingr_error.msg = error.response.data
            } catch (error) {
              this.ingr_error.msg = "Unexpected error"
            }
          })
      }
      else {
        this.ingr_error.is_error = true
        ingr.fetch_status = "error"
        this.ingr_error.msg = "Nom de l'ingrédient invalide"
      }
    },
    update_ingredient(recipe_ingr_id) {
      var ingr = this.get_ingr(recipe_ingr_id)
      ingr.fetch_status = "loading"
      this.$forceUpdate()
      api_call()
        .put('/recipes/' + this.recipe_id + "/steps/" + this.step_id + "/ingredients/" + recipe_ingr_id, {
          qty: ingr.qty,
        })
        .then(reponse => {
          this.ingr_error.is_error = false
          ingr.fetch_status = "ok"
          this.$forceUpdate()
          // this.value.need_update = !this.value.need_update
        })
        .catch(error => {
          this.ingr_error.is_error = true
          ingr.fetch_status = "error"
          this.$forceUpdate()
          try {
            this.ingr_error.msg = error.response.data
          } catch (error) {
            this.ingr_error.msg = "Unexpected error"
          }
        })
    },
    delete_ingredient(recipe_ingr_id) {
      if (!window.confirm("Voulez-vous vraiment supprimer l'ingrédient ?"))
        return
      var ingr = this.get_ingr(recipe_ingr_id)
      ingr.fetch_status = "loading"
      this.$forceUpdate()
      api_call()
        .delete('/recipes/' + this.recipe_id + "/steps/" + this.step_id + "/ingredients/" + recipe_ingr_id)
        .then(reponse => {
          this.ingr_error.is_error = false
          ingr.fetch_status = "ok"
          this.$forceUpdate()
          this.update()
        })
        .catch(error => {
          this.ingr_error.is_error = true
          ingr.fetch_status = "error"
          this.$forceUpdate()
          try {
            this.ingr_error.msg = error.response.data
          } catch (error) {
            this.ingr_error.msg = "Unexpected error"
          }
        })
    },
  },
}
</script>

<style scoped>
td, th {
  border: 1px dotted #434343 !important;
  text-align: center;
  padding: 8px;
}
.cell-button {
  padding: 0;
}
.cell-button>button {
  width: 100%;
  height: 100%;
}

.step-ingr-other-units-info {
  text-align: left;
  padding-top: 0;
  padding-bottom: 0;
  color: gray;
  font-style: italic;
}
</style>