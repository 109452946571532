<template>
  <fragment>
    <b-form-group label="Nom :" style="width: 80%; max-width: 80%;">
      <b-form-input v-model="value.name" required></b-form-input>
    </b-form-group>
    <b-form-group label="Publier ?" style="max-width: 20%;">
      <b-button :pressed.sync="value.is_draft" variant="secondary">
        <fragment v-if="value.is_draft">{{ "Brouillon" }}</fragment>
        <fragment v-else>{{ "Publié" }}</fragment>
      </b-button>
    </b-form-group>
  </fragment>
</template>

<script>
export default {
  name: 'RecipesEditForm',
  props: {
    value: {
      type: Object,
    },
  },
}
</script>